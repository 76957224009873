$font_scotch: 'scotch-text-condensed', sans-serif;
$font_vista: 'vista-slab', sans-serif;
$font_aptly: 'aptly', sans-serif;

html, body {
  font-family:$font_scotch; color:color(black);
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  display:block; float:left;
  width:100%;
  margin-bottom:0;
}

h1, .h1 {
  font-size:30px; line-height: 37px; color:color(black);
  font-family:$font_scotch;

  &.font-scotch{

    @include media-breakpoint-up(sm-320) {
      font-size:calc(30px + 30 * ((100vw - 320px) / 108)); line-height:calc(37px + 30 * ((100vw - 320px) / 108));
    }
    @include media-breakpoint-up(sm-428) {
      font-size:60px; line-height:67px;
    }
  }

  &.font-aptly{
    font-family:$font_aptly;

    @include media-breakpoint-up(sm-320) {
      font-size:calc(30px + 25 * ((100vw - 320px) / 108)); line-height:calc(37px + 23 * ((100vw - 320px) / 108));
    }
    @include media-breakpoint-up(sm-428) {
      font-size:55px; line-height:60px;
    }
  }

  &.font-vista{
    font-family:$font_vista;

    @include media-breakpoint-up(sm-320) {
      font-size:calc(30px + 37 * ((100vw - 320px) / 108)); line-height:calc(37px + 38 * ((100vw - 320px) / 108));
    }
    @include media-breakpoint-up(sm-428) {
      font-size:67px; line-height:75px;
    }
  }

}

p {
  display:block; float:left;
  width:100%;
  //margin-bottom:0;
  font-size:16px; line-height:21px; color:color(grey); font-family:$font_scotch;

  @include media-breakpoint-up(sm-375) {
    font-size:calc(16px + 4 * ((100vw - 375px) / 1065)); line-height:calc(21px + 4 * ((100vw - 375px) / 1065));
  }
  @include media-breakpoint-up(xl-1440) {
    font-size:20px; line-height:25px;
  }
  /*
  strong {
    font-family:$font_bold;
  }
  a {
    color:color(orange); text-decoration:underline!important; font-family:$font_bold;
    @include prefix(transition, all .3s ease-out, webkit moz o ms);

    &:not(.phone) {
      @include media-breakpoint-up(lg) {
        &:hover {
          color:color(orange); text-decoration:none!important;
        }
      }
    }
  }*/
}
a {
  &.phone {
    @include media-breakpoint-up(lg) {
      cursor:default;
      pointer-events:none;
    }
  }
}
