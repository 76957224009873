body{
  // fallback default variable color --> "ascona07"
  &:not([class*="main-color-"]){
    --main-color:#{color(ascona07)};
  }
  // defining each variable color --> assigned by class on body --> for example '<body class="main-color-ascona01">'
  @each $color, $value in $color-list {
    &.main-color-#{$color}{
      --main-color: #{$value};
    }
  }
}

// put here any element that need color changes according to the body class
.openMapBtn, .closeMapBtn,
.drp-buttons .btn-primary,
.daterangepicker td.active,
.daterangepicker td.active:hover,
.checkmark:before,
.header-scroll header#main-header:before,
.header-logo,
.img-logo,
.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable,
.discover-slider a h2:before,
.discover-slider a h3:before{
  background-color:var(--main-color);
}
.drp-buttons .btn-primary{
  border-color:var(--main-color);
}

.timing, .intro-section .section-icon, .text-color, a, .info-box .btn-text{
  color:var(--main-color);
  &:hover{
    color:var(--main-color);
  }
}
a.phone {
  color:color(black);

  @include media-breakpoint-down(lg) {
    color:var(--main-color);
  }
}
.title-color, .article-grid-wrapper [class*="article-"], .article-section .row>div:not(.inner-border-heading){
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color:var(--main-color);
  }
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  &.title-color{
    color:var(--main-color);
  }
}
::selection {
  background-color:var(--main-color);
  color: color(white);
}

// simple hover color with transition
header .header-menu .search-btn,
header .header-menu .menu-btn,
.hero-section .hero-controls a,
.btn-round, form > button, form > input[type="submit"], form > button[type="submit"]{
  @include prefix(transition, (all .3s ease-out), webkit moz o ms);

  @include media-breakpoint-up(lg) {
    &:hover{
      background-color:var(--main-color);
    }
  }
}
.btn-round{
  @include media-breakpoint-up(lg) {
    &:hover{
      border-color:var(--main-color);
    }
  }
}
.shop-btn {
  svg {
    .filling {
      fill:var(--main-color);
    }
  }
}


// hover color of elements child of "a" with transition
.stories-wrapper,
.article-grid-wrapper > div:not(.article-2),
.video-wrapper-big,
.events-wrapper>div,
.arrow-list,
.social-wall-wrapper{
  a{
    @include prefix(transition, (all .3s ease-out), webkit moz o ms);

    @include media-breakpoint-up(lg) {
      &:hover{
        // internal elements
        picture{
          &:before{
            border-color:var(--main-color);
          }
        }
        .btn-txt, &.load-more span{
          color:var(--main-color);
        }
        .btn-round, &.bg-video-teaser .video-teaser-info .btn-round{
          background-color:var(--main-color); border-color:var(--main-color);
          color:color(white);
        }
      }
    }
  }
}
.article-list-wrapper {
  a {
    i {
      @include prefix(transition, (all .3s ease-out), webkit moz o ms);
    }

    @include media-breakpoint-up(lg) {
      &:hover {
        .btn-round, i {
          background-color:var(--main-color);
        }
        .btn-round {
          &:hover {
            border-color:color(white);
          }
        }
      }
    }
  }
}

.arrow-list, .menu-lang, .logo-list, .social-list {
  a{
    @include media-breakpoint-up(lg) {
      &:hover{
        color:var(--main-color);
        &:before, :before{
          color:var(--main-color);
        }
      }
    }
  }
}

.article-grid-wrapper > div a, video-wrapper-big > a{
  @include media-breakpoint-up(lg) {
    &:hover{
      .btn-round{
        background-color:var(--main-color); border-color:var(--main-color);
        color:color(white);
      }
    }
  }
}


.swiper-button-prev,
.swiper-button-next{
  @include media-breakpoint-up(lg) {
    &:hover{
      &:after{
        background-color:var(--main-color)!important;
      }
    }
  }
}


// opacity bg
.icon-boxes {
  @include media-breakpoint-up(lg) {
    a{
      &:hover{
        i{
          &:after{
            background-color:var(--main-color);
          }
        }
      }
    }
  }
}

.daterangepicker td.in-range{
  background-color:var(--main-color);
}
.daterangepicker td.in-range:not(.active){
  opacity:.3;
}


// info box
.info-box{
  border-color:var(--main-color);
  &:after{
    background-color:var(--main-color);
  }
}
.search-layer, .menu-layer{
  &:after{
    background-color:var(--main-color);
  }
}

.no-hero{
  &:not(.menu-opened):not(.search-opened):not(.header-scroll){
    header .header-menu .search-btn, header .header-menu .menu-btn {
      color:var(--main-color);
      &:hover{
        background-color:transparent;
        &:before{
          background-color:var(--main-color);
          opacity:.3;
        }
        color:var(--main-color);
      }
    }
    header .header-menu .shop-btn {
      svg {
        path:not(.filling):not(.base) {
          fill:var(--main-color)!important;
        }
        path.base {
          opacity:0;
        }
      }
      &:hover{
        svg {
          .filling {
            opacity:.3;
          }
        }
      }
    }
  }
  &.header-scroll {
    header .header-menu .search-btn, header .header-menu .menu-btn {
      @include media-breakpoint-up(lg) {
        &:hover{
          background-color:var(--main-color)!important;
        }
      }
    }
  }
}

.icon-boxes.icon-boxes-small {
  .btn-round{
    border-color:var(--main-color);
    color:var(--main-color);
  }
  @include media-breakpoint-up(lg) {
    a{
      &:hover{
        .btn-round{
          background-color:var(--main-color);
          color:color(white);
        }
      }
    }
  }
}

/* TAB */

.tab-wrapper {
  .tab-buttons {
    button {
      &:before {
        background-color:var(--main-color);
      }

      &.active {
        span {
          color:var(--main-color);
        }
      }
    }
  }
  .tab-content {
    .tab-panel {
      .tab-element {
        &:before {
          background-color:var(--main-color);
        }
        > div {
          &.content {
            h2, .h2, h3, .h3 {
              a {
                @include media-breakpoint-up(lg) {
                  &:hover {
                    color:var(--main-color);
                  }
                }
              }
            }
          }
          &.image {
            a {
              &:before {
                border:2px solid var(--main-color);
              }
            }
          }
        }
      }
    }
  }
}

.pagination-wrapper {
  &:before {
    background-color:var(--main-color);
  }
  ul {
    li {
      &:after {
        color:var(--main-color);
      }
      a {
        color:var(--main-color);
      }
    }
  }
}

/* DETAILS */

.hotel-category {
  li {
    i {
      color:var(--main-color);
    }
  }
}
.booking-btn {
  background-color:var(--main-color);
}

.side-detail {
  &:before {
    background-color:var(--main-color);
  }
  &:after {
    border:1px solid var(--main-color);
  }
  ul {
    li {
      h4, .h4 {
        color:var(--main-color);
      }
      a {
        &.download {
          @include media-breakpoint-up(lg) {
            &:hover {
              color:var(--main-color);
              text-decoration:underline;
            }
          }
        }
      }
    }
  }
}

/* WEATHER */

.weather-wrapper {
  .weather {
    &:before {
      background-color:var(--main-color);
    }
    .weather-table {
      &:before {
        background-color:var(--main-color);
      }
    }
  }
}

.daily-weather {
  ul {
    li {
      > div {
        &:before {
          background-color:var(--main-color);
        }
      }
    }
  }
}

// accordion

.accordion-wrapper {
  .accordion-heading{
    &:before {
      background-color:var(--main-color);
    }
  }
  .accordion-panel{
    &:before {
      background-color:var(--main-color);
    }
  }
}


// map

.weather-map {
  svg{
    fill:var(--main-color);
  }
}


.icon-boxes>div li{
  a{
    @include prefix(transition, (all .3s ease-out), webkit moz o ms);
    @include media-breakpoint-up(lg) {
      &:hover{
        color:var(--main-color); text-decoration: underline;
      }
    }
  }
}

.menu-main{
  > ul{
    > li{
      h2, .h2, h3, .h3{
        a{
          @include prefix(transition, (all .3s ease-out), webkit moz o ms);
          @include media-breakpoint-up(lg) {
            &:hover{
              color:var(--main-color); text-decoration: none;
            }
          }
        }
      }
    }
  }
}


// iOutdoor tabs

.tabs-wrapper{
  .tabs-heading-wrapper{
    a{
      &:after {
        background-color:var(--main-color);
      }
    }
  }
}


.outdoor-results-wrapper{
  picture{
    i{
      &:after{
        background-color:var(--main-color);
      }
    }
  }
}


// meeting tables
.article-content{
  .table-responsive{
    table{
      border:1px solid color(white);
      thead{
        tr{
          &:last-child{
            border-bottom:1px solid color(white);
          }
        }
      }
      tr{
        border-bottom:1px solid color(white);
        td, th{
          position: relative;
          border-right:1px solid color(white);
          &:before{
            position: absolute; top:0; left:0; display: block;
            content:"";
            width:100%; height:100%;
            background-color:var(--main-color);
            opacity:.1;
          }
        }
        th{
          &:before{
            opacity:.25;
          }
        }
        td{
          &:first-child{
            &:before{
              opacity:.25;
            }
          }
        }
      }
    }
  }
}
