.btn-round{
  display: inline-block;
  padding:9px 20px 9px 20px; margin:0; border:1px solid color(grey-lighter);
  @include prefix(border-radius, 44px, webkit moz o ms);
  font-size:15px; line-height: 20px; color:color(grey-light); font-family: $font-vista;
  background-color:color(grey-lightest);
  @include media-breakpoint-up(lg) {
    &:hover{
      color:color(white);
    }
  }
}
.btn-txt{
  display: inline-block;
  font-size:15px; line-height: 20px; color:color(grey-light); text-transform: uppercase;
}
.btn-text{
  display: inline-block;
  padding:9px 20px 9px 20px; border:1px solid transparent;
  font-size:15px; line-height: 20px; color:color(grey-light); text-transform: uppercase; font-family: $font-vista;
  background:none;
}

.article-list-section{
  .btn-round{
    align-self: center;
    border-color:color(white);
    background-color:rgba(0,0,0,.4);
    text-align: center;

    &.btn-large{
      min-width:160px;
    }
  }
}
