.gallery-slider {
  position:relative;
  float:left;
  width:100%;
  .swiper-slide{
    picture{
      position:relative;
      @include aspect-ratio-padding(960, 720);
      background-size: cover; background-position: center; background-repeat: no-repeat;

      img{
        position: absolute; top:0; left:0;
        width:100%; 
      }
    }
  }
}

.swiper-button-prev{
  left:20px;
  font-size:40px; line-height: 40px;
  width:40px; height:40px;
  margin-top:0;
  @include prefix(transform, translateY(-50%), webkit moz o ms);

  @include media-breakpoint-up(lg) {
    left:30px;
  }
  &:after{
    content:$icon-ascona-arrow-left;
    font-family: "icomoon"; color:color(white); font-size:40px; line-height: 40px;
    width:40px; height:40px;
    background-color:#00000080;
    border-radius: 50%;
    @include prefix(transition, (all .3s ease-out), webkit moz o ms);
  }
}
.swiper-button-next{
  right:20px;
  font-size:40px; line-height: 40px;
  width:40px; height:40px;
  margin-top:0;
  @include prefix(transform, translateY(-50%), webkit moz o ms);

  @include media-breakpoint-up(lg) {
    right:30px;
  }

  &:after{
    content:$icon-ascona-arrow-right;
    font-family: "icomoon"; color:color(white); font-size:40px; line-height: 40px;
    width:40px; height:40px;
    background-color:#00000080;
    border-radius: 50%;
    @include prefix(transition, (all .3s ease-out), webkit moz o ms);
  }
}
