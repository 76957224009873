$icomoon-font-family2: "icomoon-weather" !default;
$icomoon-font-path2: "../fonts" !default;

@font-face {
  font-family: '#{$icomoon-font-family2}';
  src:
  url('#{$icomoon-font-path2}/#{$icomoon-font-family2}.ttf?sdmn5v') format('truetype'),
  url('#{$icomoon-font-path2}/#{$icomoon-font-family2}.woff?sdmn5v') format('woff'),
  url('#{$icomoon-font-path2}/#{$icomoon-font-family2}.svg?sdmn5v##{$icomoon-font-family2}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
  font-display: swap;
}

[class^="icon-ascona-weather-"], [class*=" icon-ascona-weather-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-weather' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$icon-ascona-weather-1: "\e900";
$icon-ascona-weather-2: "\e901";
$icon-ascona-weather-3: "\e902";
$icon-ascona-weather-4: "\e903";
$icon-ascona-weather-5: "\e904";
$icon-ascona-weather-6: "\e905";
$icon-ascona-weather-7: "\e906";
$icon-ascona-weather-8: "\e907";
$icon-ascona-weather-9: "\e908";
$icon-ascona-weather-10: "\e909";
$icon-ascona-weather-11: "\e90a";
$icon-ascona-weather-12: "\e90b";
$icon-ascona-weather-13: "\e90c";
$icon-ascona-weather-14: "\e90d";
$icon-ascona-weather-15: "\e90e";
$icon-ascona-weather-16: "\e90f";
$icon-ascona-weather-17: "\e910";
$icon-ascona-weather-18: "\e911";
$icon-ascona-weather-19: "\e912";
$icon-ascona-weather-20: "\e913";
$icon-ascona-weather-21: "\e914";
$icon-ascona-weather-22: "\e915";
$icon-ascona-weather-23: "\e916";
$icon-ascona-weather-24: "\e917";
$icon-ascona-weather-25: "\e918";
$icon-ascona-weather-26: "\e919";
$icon-ascona-weather-27: "\e91a";
$icon-ascona-weather-28: "\e91b";
$icon-ascona-weather-29: "\e91c";
$icon-ascona-weather-30: "\e91d";
$icon-ascona-weather-31: "\e91e";
$icon-ascona-weather-32: "\e91f";
$icon-ascona-weather-33: "\e920";
$icon-ascona-weather-34: "\e921";
$icon-ascona-weather-35: "\e922";
$icon-ascona-weather-36: "\e923";
$icon-ascona-weather-37: "\e924";
$icon-ascona-weather-38: "\e925";
$icon-ascona-weather-39: "\e926";
$icon-ascona-weather-40: "\e927";
$icon-ascona-weather-41: "\e928";
$icon-ascona-weather-42: "\e929";
$icon-ascona-weather-43: "\e92a";


.icon-ascona-weather-1 {
  &:before {
    content: $icon-ascona-weather-1;
  }
}
.icon-ascona-weather-2 {
  &:before {
    content: $icon-ascona-weather-2;
  }
}
.icon-ascona-weather-3 {
  &:before {
    content: $icon-ascona-weather-3;
  }
}
.icon-ascona-weather-4 {
  &:before {
    content: $icon-ascona-weather-4;
  }
}
.icon-ascona-weather-5 {
  &:before {
    content: $icon-ascona-weather-5;
  }
}
.icon-ascona-weather-6 {
  &:before {
    content: $icon-ascona-weather-6;
  }
}
.icon-ascona-weather-7 {
  &:before {
    content: $icon-ascona-weather-7;
  }
}
.icon-ascona-weather-8 {
  &:before {
    content: $icon-ascona-weather-8;
  }
}
.icon-ascona-weather-9 {
  &:before {
    content: $icon-ascona-weather-9;
  }
}
.icon-ascona-weather-10 {
  &:before {
    content: $icon-ascona-weather-10;
  }
}
.icon-ascona-weather-11 {
  &:before {
    content: $icon-ascona-weather-11;
  }
}
.icon-ascona-weather-12 {
  &:before {
    content: $icon-ascona-weather-12;
  }
}
.icon-ascona-weather-13 {
  &:before {
    content: $icon-ascona-weather-13;
  }
}
.icon-ascona-weather-14 {
  &:before {
    content: $icon-ascona-weather-14;
  }
}
.icon-ascona-weather-15 {
  &:before {
    content: $icon-ascona-weather-15;
  }
}
.icon-ascona-weather-16 {
  &:before {
    content: $icon-ascona-weather-16;
  }
}
.icon-ascona-weather-17 {
  &:before {
    content: $icon-ascona-weather-17;
  }
}
.icon-ascona-weather-18 {
  &:before {
    content: $icon-ascona-weather-18;
  }
}
.icon-ascona-weather-19 {
  &:before {
    content: $icon-ascona-weather-19;
  }
}
.icon-ascona-weather-20 {
  &:before {
    content: $icon-ascona-weather-20;
  }
}
.icon-ascona-weather-21 {
  &:before {
    content: $icon-ascona-weather-21;
  }
}
.icon-ascona-weather-22 {
  &:before {
    content: $icon-ascona-weather-22;
  }
}
.icon-ascona-weather-23 {
  &:before {
    content: $icon-ascona-weather-23;
  }
}
.icon-ascona-weather-24 {
  &:before {
    content: $icon-ascona-weather-24;
  }
}
.icon-ascona-weather-25 {
  &:before {
    content: $icon-ascona-weather-25;
  }
}
.icon-ascona-weather-26 {
  &:before {
    content: $icon-ascona-weather-26;
  }
}
.icon-ascona-weather-27 {
  &:before {
    content: $icon-ascona-weather-27;
  }
}
.icon-ascona-weather-28 {
  &:before {
    content: $icon-ascona-weather-28;
  }
}
.icon-ascona-weather-29 {
  &:before {
    content: $icon-ascona-weather-29;
  }
}
.icon-ascona-weather-30 {
  &:before {
    content: $icon-ascona-weather-30;
  }
}
.icon-ascona-weather-31 {
  &:before {
    content: $icon-ascona-weather-31;
  }
}
.icon-ascona-weather-32 {
  &:before {
    content: $icon-ascona-weather-32;
  }
}
.icon-ascona-weather-33 {
  &:before {
    content: $icon-ascona-weather-33;
  }
}
.icon-ascona-weather-34 {
  &:before {
    content: $icon-ascona-weather-34;
  }
}
.icon-ascona-weather-35 {
  &:before {
    content: $icon-ascona-weather-35;
  }
}
.icon-ascona-weather-36 {
  &:before {
    content: $icon-ascona-weather-36;
  }
}
.icon-ascona-weather-37 {
  &:before {
    content: $icon-ascona-weather-37;
  }
}
.icon-ascona-weather-38 {
  &:before {
    content: $icon-ascona-weather-38;
  }
}
.icon-ascona-weather-39 {
  &:before {
    content: $icon-ascona-weather-39;
  }
}
.icon-ascona-weather-40 {
  &:before {
    content: $icon-ascona-weather-40;
  }
}
.icon-ascona-weather-41 {
  &:before {
    content: $icon-ascona-weather-41;
  }
}
.icon-ascona-weather-42 {
  &:before {
    content: $icon-ascona-weather-42;
  }
}
.icon-ascona-weather-43 {
  &:before {
    content: $icon-ascona-weather-43;
  }
}
