.detail-intro {
  display:flex;
  flex-wrap:wrap;
  margin-top:15px; margin-bottom:20px;

  > div {
    display:flex;
    width:100%;

&.intro-slider-wrapper{
  position: relative;
  .openMapBtn{
    display: block; position: absolute; z-index: 2; left:50%; bottom:24px;
    width:40px; height:40px;
    font-size:40px; line-height: 40px; color:color(white);
    border:0; border-radius:50%;
    background:rgba(0,0,0,.4);
    @include prefix(transform, translateX(-50%), webkit moz o ms);
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}
    &.intro-map-wrapper{
      display:none;

      .closeMapBtn{
        display: none;
      }
    }

    @include media-breakpoint-up(lg) {
      width:50%;

      &.intro-map-wrapper{
        display:block;
      }
    }
  }
}

.map-active{
  overflow: hidden;
  header{
    z-index: 1;
  }
  .image-map-detail-wrapper{
    z-index:4;
  }
  .detail-intro {
    > div {
      &.intro-slider-wrapper{
        z-index: 1;
      }
      &.intro-map-wrapper{
        display: block; position: fixed; top:0; left:0; z-index: 2;
        width:100%; height:100%;

        .closeMapBtn{
          display: block; position: fixed; top:20px; right:20px;
          width:44px; height:44px;
          font-size:18px; line-height: 44px; color:color(white); text-align: center;
          border:2px solid white; border-radius: 50%;
        }
      }
    }
  }
}

.side-detail {
  position:relative;
  display:block; float:left;
  width:100%;
  padding:14px 14px 20px 14px;

  &:before {
    content:'';
    position:absolute; left:0; top:0;
    width:100%; height:100%;
    opacity:0.1;
    z-index:1;
  }
  &:after {
    content:'';
    position:absolute; left:0; top:0;
    width:100%; height:100%;
    z-index:2;
  }
  ul {
    position:relative;
    z-index:3;

    li {
      display:flex; flex-direction:column;
      margin-top:10px;

      &:first-child {
        margin-top:0;
      }

      h4, .h4 {
        font-size:25px; line-height:30px; font-family:$font-scotch;

        @include media-breakpoint-up(lg) {
          font-size:25px; line-height:30px;
        }
      }
      p {
        font-size:20px; line-height:25px; color:color(grey);

        @include media-breakpoint-up(lg) {
          font-size:20px; line-height:25px;
        }
      }
      a {
        font-size:20px; line-height:25px;

        @include media-breakpoint-up(lg) {
          font-size:20px; line-height:25px;
        }

        &.phone {
          @include media-breakpoint-up(lg) {
            color:color(grey);
          }
        }

        &.download {
          color:color(grey);
          overflow:hidden; text-overflow:ellipsis;
        }

        @include media-breakpoint-up(lg) {
          &:hover {
            text-decoration:underline;
          }
        }
      }
      .booking-btn {
        width:164px;
        padding:14px 16px;
        margin-top:10px;
      }
    }
  }
}
.article-content .side-detail {
  margin-bottom:1em;
  ul, ol{
    list-style: none;
    margin:0!important; padding:0!important;
    li{
      list-style: none;
      margin:0; padding:0;
      &:before{
        content:none;
      }
    }
  }
}


.detail-intro{
  &.img-logo{
    > div{  
      &.intro-slider-wrapper{  
        .openMapBtn{
          display:none;
        }
      }
    }    
  
    .intro-map-wrapper{
      > div{
        display:flex; justify-content:center; align-items:center; 
        height:100%;
        padding:20%; 
      }
    }
  }
}


