.tab-wrapper {
  float:left;
  width:100%;

  .tab-buttons {
    display:flex; flex-direction:row; align-items:flex-end; justify-content:flex-start;
    width:100%;

    button {
      position:relative;
      display:block; float:left;
      width:auto;
      padding:10px 16px 8px 16px; margin-right:3px;
      border:0 none; border-top-left-radius:6px; border-top-right-radius:6px;
      background-color:transparent;
      overflow:hidden;

      span {
        position:relative;
        font-size:20px; line-height:22px; font-family:$font_vista; text-transform:uppercase; text-align:center; color:color(white);
        z-index:2;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
      }

      &:before {
        content:'';
        position:absolute; left:0; top:0;
        width:100%; height:100%;
        opacity:0.8;
        z-index:1;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);
      }

      &.active {
        cursor:default; pointer-events:none;

        &:before {
          opacity:0.2!important;
        }
      }

      @include media-breakpoint-up(lg) {
        &:hover{
          &:before {
            opacity:1;
          }
        }
      }
    }
  }
  .tab-content {
    float:left;
    width:100%;

    .tab-panel {
      display:none; float:left;
      width:100%;
      opacity:0;
      @include prefix(transition, all .3s ease-out, webkit moz o ms);

      &.active {
        display:block;
      }
      &.fade {
        opacity:1;
      }

      .tab-element {
        position:relative;
        display:flex; flex-direction:column;
        width:100%;
        @include media-breakpoint-up(md) {
          flex-direction:row;
        }

        &:before {
          content:'';
          position:absolute; left:0; top:0;
          width:100%; height:100%;
          z-index:1;
        }
        > div {
          position:relative;
          width:100%;
          padding:20px;
          z-index:2;

          @include media-breakpoint-up(md) {
            width:50%;
          }

          &.content {
            display:flex; flex-direction:column; align-items:flex-start; justify-content:flex-start;
            padding:20px 20px 0 20px;
            @include media-breakpoint-up(md) {
              padding:20px;
            }

            h2, .h2, h3, .h3 {
              margin-top:5px; margin-bottom:5px;
              font-size:calc(30px + 5 * ((100vw - 375px) / 617)); line-height: calc(35px + 5 * ((100vw - 375px) / 617)); font-family:$font-scotch; color:color(grey); font-weight:400; text-align:left;

              a {
                color:color(grey);
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
              }

              @include media-breakpoint-up(lg) {
                font-size:35px; line-height: 40px;
              }

              &:first-child {
                margin-top:0!important;
              }
            }
            p {
              margin-top:5px; margin-bottom:0;
              color:color(grey); font-family: $font-scotch; font-weight:400; font-size:calc(20px + 5 * ((100vw - 375px) / 617)); line-height: calc(25px + 5 * ((100vw - 375px) / 617));

              &:first-child {
                margin-top:0!important;
              }

              @include media-breakpoint-up(md) {
                &:last-of-type {
                  margin-bottom:1em!important;
                }
              }

              @include media-breakpoint-up(lg) {
                font-size:25px; line-height:30px;
              }
            }
          }
          &.image {
            position: relative;
            .booking-btn {
              position: absolute; top:20px; left:20px; z-index: 3;
              width:100px; height:52px;
              padding:9px 10px;
            }
            &:before {
              content:'';
              position: absolute; top:20px; left:20px; z-index: 2; opacity:0;
              width:100px; height:52px;
              padding:9px 10px;
              background-color:color(white);
              @include media-breakpoint-up(md) {
                content:none;
              }
            }
            a {
              position:relative;
              display:block; float:left;
              width:100%;

              &:before {
                content:'';
                position:absolute; left:0; top:0;
                width:100%; height:100%;
                z-index:2;
                opacity:0;
                @include prefix(transition, all .3s ease-out, webkit moz o ms);
              }

              picture {
                position:relative;
                z-index:1;
                @include aspect-ratio-padding(572, 322);
                background-size: cover; background-position: center; background-repeat: no-repeat;

                img{
                  position: absolute; top:0; left:0;
                  width:100%; height:100%;
                }
              }

              @include media-breakpoint-up(lg) {
                &:hover{
                  &:before {
                    opacity:1;
                  }
                }
              }
            }
          }
        }
      }
      .tab-element, .pagination-wrapper {
        &:nth-child(odd) {
          &:before {
            opacity:0.2;
          }
        }
        &:nth-child(even) {
          &:before {
            opacity:0.1;
          }
        }
      }
    }
  }
}

.pagination-wrapper {
  position:relative;
  display:block; float:left;
  width:100%;
  padding:14px 20px;

  &:before {
    content:'';
    position:absolute; left:0; top:0;
    width:100%; height:100%;
    z-index:1;
  }
  ul {
    position:relative;
    z-index:2;

    li {
      position:relative;
      width:auto;
      margin-right:10px; padding-right:10px;

      &:after {
        content:'•';
        position:absolute; right:0; top:0;
        font-size:20px; line-height:22px; font-family:$font_vista; font-weight:700;
        opacity:0.5;
        @include prefix(transform, translateX(50%), webkit moz o ms);
      }

      &:first-child {
        padding-right:0;

        &:after {
          content:none;
        }
      }
      &:last-child {
        padding-right:0; margin-right:0;

        &:after {
          content:none;
        }
      }

      a {
        display:block; float:left;
        width:auto;
        border:0 none;
        font-size:20px; line-height:22px; font-family:$font_vista; font-weight:700;
        opacity:0.5;
        background-color:transparent;
        @include prefix(transition, all .3s ease-out, webkit moz o ms);

        &.active {
          opacity:1;
          cursor:default; pointer-events:none;
        }

        @include media-breakpoint-up(lg) {
          &:hover{
            opacity:1;
          }
        }
      }
    }
  }
}
.pagination-wrapper {
  &:nth-child(odd) {
    &:before {
      opacity:0.2;
    }
  }
  &:nth-child(even) {
    &:before {
      opacity:0.1;
    }
  }
}

.booking-btn {
  width:200px;
  padding:14px 20px;
  margin-top:auto;
}
.tab-wrapper {
  .tab-content {
    .tab-element {
      &.book-with{
        > div {
          &.image {
            &:before {
              opacity:1;
            }
          }
        }
      }
    }
  }
}
