// Hero
.hero-section{
  overflow: hidden;
  margin: 0; padding: 0;
  width: 100%; height: 100vh;

  .hero-content{
    position: relative; display: block;
    height:100%;

    .bg-cover{
      z-index: 1; position: absolute; top:0; left:0;
      width: 100%; height: 100%;
      background-position: center center; background-repeat: no-repeat; background-size: cover;

      img{
        object-fit: cover;
        height:100%;
      }
    }

    video{
      position: absolute; top:0; left:0;
      width:100%; height:100%;
      object-fit: cover; object-position: center center;
    }
    .hero-claim{
      position: absolute; z-index: 2; display: flex; align-items: center; justify-content: center; flex-direction: column;
      width:100%; height:100%;
      padding-left:15px; padding-right:15px;

      h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        text-align: center; color:color(white); text-shadow: 0px 3px 6px #00000029; font-weight: 700;

        a{
          color:color(white);
        }
      }
    }

    .hero-claim{
      .hero-icon{
        margin-top:20px;
        color:color(white); font-size: 24px; line-height: 24px;
        width:24px; height:24px;
      }
    }
  }
  .hero-controls{
    position: absolute; z-index: 2; display: flex; flex-direction: column; align-items: center;
    bottom: 20px; left: 0;
    width: 100%; height: 40px;

    ul{
      float:none;
      width:auto;

      li{
        width:auto;
        margin-right:10px;

        &:last-child{
          margin-right:0;
        }
      }
    }

    a{
      display: block; float:left;
      width:40px; height:40px; border-radius:50%; overflow: hidden;
      @include bglayer();

      &:before{
        display: block;
        font-family: "icomoon"; font-size: 40px; line-height: 40px; color:color(white);
      }

      &.play{
        &:before{
          content:$icon-ascona-play;
        }
      }
      &.pause{
        &:before{
          content:$icon-ascona-pause;
        }
      }

      &.mute{
        &:before{
          content:$icon-ascona-sound;
        }
      }
      &.unmute{
        &:before{
          content:$icon-ascona-sound-close;
        }
      }
    }
  }
}


// Article grid
.article-grid-wrapper {
  display: grid; position: relative; overflow: hidden;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(8, auto);
  grid-column-gap: $grid-column-gap; grid-row-gap: $grid-row-gap;
  margin-bottom: $section-margin;
  @include media-breakpoint-up(lg) {
    overflow: auto;
  }

  &:after{
    content:""; position: absolute; bottom:0; left:0; display: block;
    width:100%;
    border-top:0.001px solid color(grey-lighter);
  }

  > div{
    position: relative;

    a{
      display: block; float:left; position: relative;
      padding-bottom:20px;

      h2, .h2, h3, .h3{
        font-size:calc(30px + 10 * ((100vw - 375px) / 617)); line-height:calc(35px + 10 * ((100vw - 375px) / 617)); font-family: $font-aptly; font-weight: 300;
        margin-bottom:10px;
        @include prefix(transition, (all .3s ease-out), webkit moz o ms);

        @include media-breakpoint-up(lg) {
          font-size:40px; line-height: 45px;
        }
      }

      picture{
        position: relative;
        margin-bottom:15px;
        &:before{
          content:""; position: absolute; top:0; left:0;
          width:100%; height:100%;
          border:2px solid transparent;
          @include prefix(transition, (all .3s ease-out), webkit moz o ms);
        }
      }
      img{
        display: block;
        width:100%;
      }

      p{
        color:color(grey); font-size:calc(15px + 5 * ((100vw - 375px) / 617)); line-height:calc(20px + 5 * ((100vw - 375px) / 617)); font-family: $font-scotch;

        @include media-breakpoint-up(lg) {
          font-size:20px; line-height: 25px;
        }
      }

      .btn-txt{
        margin-top:-5px!important;
        font-family: $font-vista; font-weight: 300;
      }

      @include media-breakpoint-up(lg) {
        &:hover{
          h2, .h2, h3, .h3{
            text-decoration: underline;
          }
        }
      }
    }

    &.article-1{
      h2, .h2, h3, .h3{
        font-size:calc(40px + 10 * ((100vw - 375px) / 617)); line-height:calc(45px + 10 * ((100vw - 375px) / 617));

        @include media-breakpoint-up(lg) {
          font-size:50px; line-height: 55px;
        }
      }
      p{
        font-size:calc(20px + 5 * ((100vw - 375px) / 617)); line-height:calc(25px + 5 * ((100vw - 375px) / 617));

        @include media-breakpoint-up(lg) {
          font-size:25px; line-height: 30px;
        }
      }

      &:before{
        content:""; position: absolute; top:0; right:-($grid-column-gap/2); display: block;
        width:1px; height:calc(100% - #{$grid-row-gap/2});
        background-color:color(grey-lighter);

        @media (min-resolution: 105.6dpi) {
          width: 0.90px;
        }

        @media (min-resolution: 120dpi) {
          width: 0.75px;
        }

        @media (min-resolution: 144dpi) {
          width: 0.666px;
        }

        @media (min-resolution: 168dpi) {
          width: 0.571px;
        }

        @media (min-resolution: 192dpi) {
          width: 0.5px;
        }
      }

      &:after{
        content:""; position: absolute; bottom:0; left:0; display: block;
        width:calc(100% + #{$grid-column-gap});
        border-top:0.001px solid color(grey-lighter);
      }
    }

    &.article-2{
      h2, .h2, h3, .h3{
        font-size:calc(35px - 10 * ((100vw - 375px) / 617)); line-height:calc(40px - 10 * ((100vw - 375px) / 617)); font-family: $font-vista; font-weight: 700;
        margin-bottom:15px;
        width: calc(100% - 98px);
        @include media-breakpoint-up(lg) {
          width: 100%;
          font-size:25px; line-height: 30px;
        }
      }
      picture{
        max-width:81px;
        margin-right:17px;

        @include media-breakpoint-up(lg) {
          max-width: 100%;
          margin-right:0;
        }
      }

      &:before{
        content:""; position: absolute; top:0; right:-($grid-column-gap/2); display: block;
        width:1px; height:100%;
        background-color:color(grey-lighter);
        @media (min-resolution: 105.6dpi) {
          width: 0.90px;
        }

        @media (min-resolution: 120dpi) {
          width: 0.75px;
        }

        @media (min-resolution: 144dpi) {
          width: 0.666px;
        }

        @media (min-resolution: 168dpi) {
          width: 0.571px;
        }

        @media (min-resolution: 192dpi) {
          width: 0.5px;
        }
      }

      &:after{
        content:""; position: absolute; bottom:0; left:0; display: block;
        width:100%;
        border-top:0.001px solid color(grey-lighter);
      }
      .btn-round{
        margin: 0 auto;
        @include media-breakpoint-up(lg) {
          margin:0;
        }
      }
    }

    &.article-3{
      &:before{
        content:""; position: absolute; top:0; right:-($grid-column-gap/2); display: block;
        width:1px; height:calc(100% - #{$grid-row-gap/2});
        background-color:color(grey-lighter);
        @media (min-resolution: 105.6dpi) {
          width: 0.90px;
        }

        @media (min-resolution: 120dpi) {
          width: 0.75px;
        }

        @media (min-resolution: 144dpi) {
          width: 0.666px;
        }

        @media (min-resolution: 168dpi) {
          width: 0.571px;
        }

        @media (min-resolution: 192dpi) {
          width: 0.5px;
        }
      }
      &:after{
        content:""; position: absolute; bottom:0; left:0; display: block;
        width:100%;
        border-top:0.001px solid color(grey-lighter);

        @include media-breakpoint-up(lg) {
          content:none;
        }
      }
      p{
        display: none;
        @include media-breakpoint-up(lg) {
          display: block;
        }
      }
      .btn-txt{
        margin-top:0;
        @include media-breakpoint-up(lg) {
          margin-top:11px;
        }
      }
    }

    &.article-4{
      &:before{
        content:""; position: absolute; top:-#{$grid-row-gap}; right:-($grid-column-gap/2); display: block;
        width:1px; height:calc( 100% + #{$grid-row-gap/2} );
        background-color:color(grey-lighter);

        @media (min-resolution: 105.6dpi) {
          width: 0.90px;
        }

        @media (min-resolution: 120dpi) {
          width: 0.75px;
        }

        @media (min-resolution: 144dpi) {
          width: 0.666px;
        }

        @media (min-resolution: 168dpi) {
          width: 0.571px;
        }

        @media (min-resolution: 192dpi) {
          width: 0.5px;
        }
      }
      p{
        display: none;
        @include media-breakpoint-up(lg) {
          display: block;
        }
      }
      .btn-txt{
        margin-top:0;
        @include media-breakpoint-up(lg) {
          margin-top:11px;
        }
      }
    }

    &.article-5{
      h2, .h2, h3, .h3{
        font-size:calc(30px + 25 * ((100vw - 375px) / 617)); line-height:calc(35px + 25 * ((100vw - 375px) / 617));

        @include media-breakpoint-up(lg) {
          font-size:55px; line-height: 60px;
        }
      }

      &:before{
        content:""; position: absolute; top:-#{$grid-row-gap/2}; right:-($grid-column-gap/2); display: block;
        width:1px; height:calc( 100% + #{$grid-row-gap/2} );
        background-color:color(grey-lighter);
        @include media-breakpoint-up(lg) {
          content:none;
        }

        @media (min-resolution: 105.6dpi) {
          width: 0.90px;
        }

        @media (min-resolution: 120dpi) {
          width: 0.75px;
        }

        @media (min-resolution: 144dpi) {
          width: 0.666px;
        }

        @media (min-resolution: 168dpi) {
          width: 0.571px;
        }

        @media (min-resolution: 192dpi) {
          width: 0.5px;
        }
      }

      &:after{
        content:""; position: absolute; bottom:0; left:0; display: block;
        width:100%;
        border-top:0.001px solid color(grey-lighter);
      }
    }
    &.article-6{
      p{
        display: none;
        @include media-breakpoint-up(lg) {
          display: block;
        }
      }
      &:before{
        content:""; position: absolute; top:-#{$grid-row-gap}; right:-($grid-column-gap/2); display: block;
        width:1px; height:calc( 100% + #{$grid-row-gap/2} );
        background-color:color(grey-lighter);
        @include media-breakpoint-up(lg) {
          content:none;
        }

        @media (min-resolution: 105.6dpi) {
          width: 0.90px;
        }

        @media (min-resolution: 120dpi) {
          width: 0.75px;
        }

        @media (min-resolution: 144dpi) {
          width: 0.666px;
        }

        @media (min-resolution: 168dpi) {
          width: 0.571px;
        }

        @media (min-resolution: 192dpi) {
          width: 0.5px;
        }
      }
      .btn-txt{
        margin-top:0;
        @include media-breakpoint-up(lg) {
          margin-top:11px;
        }
      }
    }
  }
}

.article-1 { grid-area: 1 / 1 / 3 / 5; }
.article-2 { grid-area: 3 / 1 / 5 / 5; }
.article-3 { grid-area: 5 / 3 / 7 / 5; }
.article-4 { grid-area: 7 / 3 / 9 / 5; }
.article-5 { grid-area: 5 / 1 / 8 / 3; }
.article-6 { grid-area: 8 / 1 / 9 / 3; }

@media only screen and (min-width: 992px) {
  .article-grid-wrapper {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto auto auto 1fr;
    grid-column-gap: 30px; grid-row-gap: 20px;
  }

  .article-1 { grid-area: 1 / 1 / 3 / 4; }
  .article-2 { grid-area: 1 / 4 / 3 / 5; }
  .article-3 { grid-area: 3 / 1 / 5 / 3; }
  .article-4 { grid-area: 3 / 3 / 5 / 5; }
  .article-5 { grid-area: 1 / 5 / 4 / 7; }
  .article-6 { grid-area: 4 / 5 / 5 / 7; align-self: start; }
}

// Article grid - Video
.article-section{
  .video-wrapper-big{
    h3, p{
      width:100%;
      @include media-breakpoint-up(lg) {
        max-width:67%;
      }
    }
    a{
      display: block; float:left; position: relative;
      padding-bottom:20px;

      picture{
        position: relative;
        margin-bottom:15px;
        &:before{
          content:""; position: absolute; top:0; left:0;
          width:100%; height:100%;
          border:2px solid transparent;
          @include prefix(transition, (all .3s ease-out), webkit moz o ms);
        }
      }

      img{
        display: block;
        width:100%;
      }

      h2, .h2, h3, .h3{
        font-size:calc(40px + 20 * ((100vw - 375px) / 617)); line-height:calc(45px + 22 * ((100vw - 375px) / 617)); font-family: $font-aptly!important; font-weight: 300;
        margin-bottom:10px;
        @include prefix(transition, (all .3s ease-out), webkit moz o ms);
        @include media-breakpoint-up(lg) {
          font-size:60px; line-height:67px;
        }
      }

      p{
        color:color(grey); font-size:20px; line-height: 25px; font-family: $font-scotch;
      }

      @include media-breakpoint-up(lg) {
        &:hover{
          h2, .h2, h3, .h3{
            text-decoration: underline;
          }
        }
      }
    }
    span{
      &.btn-txt{
        float:left; clear:both; display: inline-block;
        margin-top:-5px;
      }
    }
    article{
      display: block;
    }
  }
}

// Top events
.events-wrapper{
  display: flex; flex-direction: column;
  width:100%;
  @include media-breakpoint-up(sm-428) {
    flex-direction: row;
  }
  > div{
    position: relative; display: flex;
    video{
      position: absolute; top:0; left:0; display: block; z-index:1;
      width:100%; height: 100%;
      object-fit: cover; object-position: center center;
    }
    &.spotlight{
      width:100%;
      margin-right: 15px;
      @include media-breakpoint-up(sm-428) {
        width:39.307%;
      }
      .bg-video-teaser{
        padding-top:100%;
        @include media-breakpoint-up(sm-428) {
          @include aspect-ratio-padding(209, 500);
        }
        @include media-breakpoint-up(lg) {
          @include aspect-ratio-padding(748, 1078);
        }
      }
    }
    &.two-boxes{
      flex-direction: column; flex-grow: 1;
      > div{
        margin-top:15px;
        @include media-breakpoint-up(sm-428) {
          margin-top:0;
        }
        position: relative;
        width:100%;
        .bg-video-teaser{
          padding-top:100%;
          @include media-breakpoint-up(sm-428) {
            @include aspect-ratio-padding(209, 245);
          }
          @include media-breakpoint-up(lg) {
            @include aspect-ratio-padding(1140, 416);
          }
        }
        &:nth-child(2){
          margin-top:15px;
          .bg-video-teaser{
            padding-top:100%;
            @include media-breakpoint-up(sm-428) {
              @include aspect-ratio-padding(209, 245);
            }
            @include media-breakpoint-up(lg) {
              @include aspect-ratio-padding(1140, 647);
            }
          }
        }
      }
    }
    article{
      position: absolute; top:0; left:0; display: block;
    }
    .bg-video-teaser{
      display: block; position: relative; z-index: 2;
      width: 100%;
      @include prefix(transition, (all .3s ease-out), webkit moz o ms);

      picture{
        opacity:1;
        @include prefix(transition, (all .3s ease-out), webkit moz o ms);
      }

      &.cover-hide {
        picture{
          opacity:0;
        }
      }

      &:after{
        content:""; opacity: 0.5; display: block; z-index: 1; position: absolute; top:0; left:0;
        width:100%; height:100%;
        background: #000000 0% 0%;
      }
      .video-teaser-info{
        z-index: 2; position: absolute; top:50%; flex-wrap: wrap; align-content: center; align-items: center; flex-direction: column; display: flex;
        width:100%;
        text-align: center;
        padding:0 20px;
        @include prefix(transform, translateY(-50%), webkit moz o ms);

        h2, .h2, h3, .h3{
          font-size:30px; line-height: 35px; color:color(white); font-weight: 700; font-family: $font-aptly;margin-bottom:6px;
        }
        p{
          font-size:20px; line-height: 25px; color:color(white); font-weight: 300; font-family: $font-aptly;
        }
        i{
          width:40px; height: 40px;
          font-size:40px; line-height: 40px; color:color(white); font-weight: 300;
          margin-bottom:6px;
        }
        .btn-round{
          margin-top:11px;
          border-color:color(white);
          color:color(white);
          background-color:#00000080;
        }
      }
      .bg-teaser-img{
        position: absolute; top:0; left:0;
        height: 100%; width: 100%;
        img{
          position: absolute; top:0; left:0; object-fit: cover;
          height: 100%; width: 100%;
        }
      }
    }
  }
}

// Discover slider
.container .row>div{
  &.discover-slider{
    padding-left: 0; padding-right:0;
    @include media-breakpoint-up(xl-1440) {
      padding-left:8px; padding-right:8px;
    }
  }
}

/*.slider-section[data-name="discover-the-region"]{
  .swiper-button-prev{
    left:20px;
    font-size:40px; line-height: 40px;
    width:40px; height:40px;
    @include media-breakpoint-up(lg) {
      left:30px;
    }
    &:after{
      content:$icon-ascona-arrow-left;
      font-family: "icomoon"; color:color(white); font-size:40px; line-height: 40px;
      width:40px; height:40px;
      background-color:#00000080;
      border-radius: 50%;
      @include prefix(transition, (all .3s ease-out), webkit moz o ms);
    }
  }
  .swiper-button-next{
    right:20px;
    @include media-breakpoint-up(lg) {
      right:30px;
    }
    font-size:40px; line-height: 40px;
    width:40px; height:40px;
    &:after{
      content:$icon-ascona-arrow-right;
      font-family: "icomoon"; color:color(white); font-size:40px; line-height: 40px;
      width:40px; height:40px;
      background-color:#00000080;
      border-radius: 50%;
      @include prefix(transition, (all .3s ease-out), webkit moz o ms);
    }
  }
}*/
.discover-slider{
  height:450px;
  @include media-breakpoint-up(lg) {
    height:674px;
  }
  .swiper{
    overflow: visible;
  }
  .swiper-slide-active{
    a{
      z-index: 2;
    }
    h2, .h2, h3, .h3 {
      opacity: 1;
      > span{
        right:-10px;
        @include media-breakpoint-up(lg) {
          right:0px;
        }
      }
    }
  }
  .swiper-slide-prev{
    a{
      right:-200px;
    }
  }
  .swiper-slide-next{
    a{
      left:0; z-index: 1;
      @include media-breakpoint-up(lg) {
        left:-200px;
      }
    }
  }
  a{
    display: block; float:left; overflow: hidden; position: relative; z-index: 1;
    width:100%;
    left:0;
    background-size:cover; background-position: center center; background-repeat: no-repeat;
    @include prefix(transition, (all .75s ease), webkit moz o ms);

    height:450px;
    @include media-breakpoint-up(lg) {
      height:674px;
    }

    h2, .h2, h3, .h3 {
      position: absolute; top:0; left:0; display: block; z-index: 2; opacity:0; overflow: hidden;
      height:100%; width: 75px;
      @include prefix(transition, (all .5s ease), webkit moz o ms); transition-delay:.1s;
      @include media-breakpoint-up(lg) {
        width:120px;
      }

      &:before{
        content:""; display: block; position:absolute; top:0; left:0; z-index: 1; opacity: 0.8;
        width:100%; height:100%;
      }
      > span{
        position: absolute; bottom:30px; right:-75px; display: block; z-index: 3;
        width:75px; height:auto;
        @include prefix(transition, (all .5s ease), webkit moz o ms); transition-delay:.3s;

        span{
          display: block;
          color:color(white); font-family: $font-vista; font-size: 34px; line-height: 34px; font-weight: 700; text-transform: uppercase; white-space: nowrap;
          @include prefix(transform, rotate(-90deg), webkit moz o ms); opacity: 0.75;
          @include prefix(transition, (all .5s ease-out), webkit moz o ms);
          will-change: opacity;
          @include media-breakpoint-up(lg) {
            font-size: 47px; line-height: 47px;
          }
        }
      }
    }
    @include media-breakpoint-up(lg) {
      &:hover{
        h2, .h2, h3, .h3 {
          span{
            opacity: 1;
          }
        }
      }
    }
  }
}

// Arrow list
.arrow-list{
  list-style: none;
  &.arrow-list-2cols{
    @include media-breakpoint-up(lg) {
      column-count: 2; column-gap: 16px;
    }
  }
  &.arrow-list-3cols{
    @include media-breakpoint-up(lg) {
      column-count: 3; column-gap: 16px;
    }
  }
  li{
    font-family: $font_vista; font-size:25px; line-height: 30px; color:color(grey); font-weight: 700;
    list-style: none;
    margin-top:1px; margin-bottom:1px;
  }
  a{
    position: relative; display: block; float:left;
    font-size:25px; line-height: 30px; color:color(grey); font-weight: 700;
    padding-left:35px;
    width: auto;
    @include prefix(transition, (all .3s ease-out), webkit moz o ms);

    &:before{
      content:$icon-ascona-arrow-right-simple; position: absolute; top:4px; left:0; display: block;
      font-size:24px; line-height:24px; color:color(grey); font-family: "icomoon"; font-weight: normal;
      @include prefix(transition, (all .3s ease-out), webkit moz o ms);
    }
  }
}

// Social list
.social-list{
  margin: 0 0 -10px 0;
  @include media-breakpoint-up(lg) {
    margin:0;
  }

  li{
    font-size:45px; line-height: 45px; color:color(grey);
    margin-right:10px; margin-bottom:10px;
    width:auto; height:45px;
    @include media-breakpoint-up(lg) {
      font-size:50px; line-height: 50px;
      height:50px;
      margin-right:12px; margin-bottom:0;
    }

    a{
      position: relative; display: block; float:left;
      font-size:45px; line-height: 45px; color:color(grey);

      @include media-breakpoint-up(lg) {
        font-size:50px; line-height: 50px;
      }

      :before{
        @include prefix(transition, (all .3s ease-out), webkit moz o ms);
      }
    }
  }
}

// Title section
.title-section{
  &.inner-border-heading{
    padding-bottom:$section_margin/4;
  }
  .inner-border-heading{
    text-align: center;

    h1, .h1, h2, .h2{
      font-size:calc(25px + 5 * ((100vw - 375px) / 617)); line-height:calc(30px + 5 * ((100vw - 375px) / 617)); color:color(grey);

      @include media-breakpoint-up(lg) {
        font-size:30px; line-height: 35px;
      }

      a{
        font-size:calc(25px + 5 * ((100vw - 375px) / 617)); line-height:calc(30px + 5 * ((100vw - 375px) / 617)); color:color(grey);

        @include media-breakpoint-up(lg) {
          font-size:30px; line-height: 35px;
        }
      }
    }
  }
}

.inner-border-heading{
  position: relative;
  margin-bottom: $section_margin; padding-bottom:$section_margin;

  h2, .h2{
    font-size: 20px; line-height: 25px; color:color(grey);

    @include media-breakpoint-up(lg) {
      font-size:25px; line-height: 30px;
    }

    a{
      font-size:20px; line-height: 25px; color:color(grey);

      @include media-breakpoint-up(lg) {
        font-size:25px; line-height: 30px;
      }
    }
  }

  &:after{
    content:""; position: absolute; bottom:0; left:50%; display: block;
    width:100vw;
    border-top:0.001px solid color(grey-lighter);
    @include prefix(transform, translateX(-50%), webkit moz o ms);
  }
}

// Search events
.events-search-wrapper{
  position: relative;
  margin-top:$section_margin; padding-top:$section-margin;

  p{
    color:color(grey); font-family: $font-scotch; font-size:20px; line-height: 25px;
  }
  form{
    margin-top:15px;

    input, select{
      width:100%;
    }
    .btn-round{
      float:right;
      /*@include media-breakpoint-up(lg) {
        float:left;
      }*/
    }
  }

  &:after{
    content:""; position: absolute; top:0; left:8px; display: block;
    width:calc(100% - 16px);
    border-top:0.001px solid color(grey-lighter);
  }

  &.no-borders {
    margin-top:-15px; padding-top:5px; padding-bottom:5px;

    form {
      margin-bottom:-10px;
    }
    &:after {
      content:none;
    }
  }
}

// Icon boxes
.iconbox-section{
  .icon-boxes:not(.icon-boxes-small){
    margin-bottom:-($section_margin*2);
  }
}
.icon-boxes{
  overflow: hidden;
  text-align: center;

  @include media-breakpoint-up(lg) {
    overflow: auto;
  }

  &:not(.icon-boxes-small){
    > div{
      margin-bottom: 30px;

      &:nth-child(odd){
        &:after{
          height:calc(100% + #{$section_margin/2});
        }
      }
      @include media-breakpoint-up(lg) {
        &:nth-child(even){
          &:before{
            height:calc(100% + #{$section_margin/2});
          }
        }
      }
    }
    h2, .h2, h3, .h3{
      margin-bottom:0;

      @include media-breakpoint-up(lg) {
        font-size:30px; line-height: 35px;
        margin-bottom:0;
      }
    }
  }

  > div{
    position: relative;
    margin-bottom:($section_margin*2+7);

    &:nth-child(odd){
      &:after{
        position: absolute; top:-($section_margin/2); right:0; content:""; display: block;
        width:1px; height:calc(100% + #{$section_margin});
        background-color:color(grey-lighter);

        @media (min-resolution: 105.6dpi) {
          width: 0.90px;
        }

        @media (min-resolution: 120dpi) {
          width: 0.75px;
        }

        @media (min-resolution: 144dpi) {
          width: 0.666px;
        }

        @media (min-resolution: 168dpi) {
          width: 0.571px;
        }

        @media (min-resolution: 192dpi) {
          width: 0.5px;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      &:nth-child(even){
        &:before{
          position: absolute; top:-($section_margin/2); right:0; content:""; display: block;
          width:1px; height:calc(100% + #{$section_margin});
          background-color:color(grey-lighter);

          @media (min-resolution: 105.6dpi) {
            width: 0.90px;
          }

          @media (min-resolution: 120dpi) {
            width: 0.75px;
          }

          @media (min-resolution: 144dpi) {
            width: 0.666px;
          }

          @media (min-resolution: 168dpi) {
            width: 0.571px;
          }

          @media (min-resolution: 192dpi) {
            width: 0.5px;
          }
        }
      }
    }
    &:not(:nth-child(1)):not(:nth-child(2)){
      &:before{
        content:""; position: absolute; top:-($section_margin); left:0; content:""; display: block;
        width:calc(100% + #{$section_margin});
        border-top:0.001px solid color(grey-lighter);

        @include media-breakpoint-up(lg) {
          content:none;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      &:before{
        content:none;
      }
    }

    &:last-child{
      &:after{
        content:none!important;
      }
    }
    a{
      float:left; display: block;
      width:100%;
    }
  }

  h2, .h2, h3, .h3{
    font-family: $font-aptly; font-size:calc(25px + 5 * ((100vw - 375px) / 617)); line-height: calc(30px + 5 * ((100vw - 375px) / 617));
    margin-top:4px; margin-bottom:10px;

    @include media-breakpoint-up(lg) {
      font-size:30px; line-height: 35px;
      margin-bottom:13px;
    }
  }

  li, p{
    font-family: $font-scotch; color:color(grey); font-size:calc(15px + 5 * ((100vw - 375px) / 617)); line-height: calc(20px + 5 * ((100vw - 375px) / 617));

    @include media-breakpoint-up(lg) {
      font-size:20px; line-height: 25px;
    }

    a{
      color:color(grey);
    }
  }

  i{
    position: relative;
    font-size:90px; line-height: 92px;
    width:90px; height:90px;
    &:after{
      content:""; opacity:.2; position: absolute; top:0; left:0; display: block;
      width:100%; height:100%;
      border-radius:50%;
      background-color:transparent;
      @include prefix(transition, (all .3s ease-out), webkit moz o ms);
    }
  }
  a{
    @include media-breakpoint-up(lg) {
      &:hover{
        h2, .h2, h3, .h3{
          text-decoration: underline;
        }
      }
    }
  }
}

// Image grid
.image-grid{
  > div{
    margin-bottom:16px;

    .bg-image{
      &:nth-child(2){
          margin-top:16px;
      }
    }
  }
  .bg-image{
    display: block; position: relative;
    background-position: center center; background-size:cover; background-repeat: no-repeat;
    @include prefix(transition, (all .3s ease-out), webkit moz o ms);

    h2, .h2, h3, .h3{
      position: absolute; bottom:calc(7px + 7 * ((100vw - 375px) / 617)); left:calc(6px + 10 * ((100vw - 375px) / 617)); display: block; z-index: 3;
      width:calc(100% - 12px);
      color:color(white); font-size:calc(16px + 29 * ((100vw - 375px) / 617));  line-height:calc(20px + 30 * ((100vw - 375px) / 617)); font-family: $font-aptly; font-weight: 700;
      @include prefix(transition, (all .3s ease-out), webkit moz o ms);
      @include media-breakpoint-up(lg) {
        bottom:14px; left:16px;
        font-size:45px; line-height: 50px;
        width:calc(100% - 32px);
      }
    }
    &:before{
      content:""; display: block; z-index: 2; position: absolute; bottom:0; left:0;
      width:100%; max-height: 210px; height: 100%;
      background:transparent linear-gradient(180deg, #00000000 0%, #000000D1 61%, #000000 100%) 0% 0% no-repeat padding-box;
    }
    &:after{
      content:""; display: block; z-index: 1;
      width:100%;
      padding-bottom:100%;
    }

    &.bg-image-full{
      &:after{
        padding-bottom:75%;
      }
    }

    @include media-breakpoint-up(lg) {
      &:hover{
        h2, .h2, h3, .h3{
          text-decoration: underline;
        }
      }
    }
  }
}

// Social wall
.social-wall-wrapper{
  .social-wall{
    float:left;
    width:100%;
    margin-bottom:14px;
  }
  a{
    &.load-more{
      display: block; clear: both;
      width:min-content;
      margin:0 auto;
      color:color(grey); text-transform: uppercase; font-family: $font-vista; font-size:15px; line-height: 20px; text-align: center; white-space: nowrap;
      @include prefix(transition, (all .3s ease-out), webkit moz o ms);
    }
  }
}

.stories-section, .article-list-section{

  &[data-name="downloads"]{
    article{
      h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
        font-family: $font-scotch;
      }
    }
  }
  .stories-wrapper{
    position: relative;
    &::after{
      content: ""; position: absolute; top: 0;  right: 0px;  display: block;
      width: 10px; height: 100%;
      background-color: color(white);
    }
  }
  .stories-wrapper, .article-list-wrapper{
    &.stories-wrapper-mobile-columns{
      position: relative;
      &:after {
          position: absolute; content: ""; right:-5px; top: 0;
          width: 15px; height: 100%;
          background: color(white);
      }
      > div{
        &:before{
          content:'';
        }

        &:not(:nth-child(1)):not(:nth-child(2)){
          &:after{
            content:""; position: absolute; top:-($section_margin/2); left:$section_margin/2; content:""; display: block;
            width:calc(100%); height:1px;
            background-color:color(grey-lighter);
            @include media-breakpoint-up(lg) {
              content:none;
            }
          }
        }
        @include media-breakpoint-up(lg) {
          &:after{
            content:none;
          }
        }
      }
      article{
        padding-bottom:20px;
        @include media-breakpoint-up(lg) {
          padding-bottom:0;
        }

        h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
          font-size:calc(25px + 10 * ((100vw - 375px) / 617));  line-height:calc(30px + 10 * ((100vw - 375px) / 617));
          @include media-breakpoint-up(lg) {
            font-size:35px; line-height: 40px;
          }
        }
      }
    }
    > div{
      position: relative;

      &:before{
        content:none; position: absolute; top:0; right:0; display: block;
        width:1px; height:calc(100% - #{$section_margin});
        background-color:color(grey-lighter);

        @include media-breakpoint-up(lg) {
          content:'';
          height:100%;
        }

        @media (min-resolution: 105.6dpi) {
          width: 0.90px;
        }

        @media (min-resolution: 120dpi) {
          width: 0.75px;
        }

        @media (min-resolution: 144dpi) {
          width: 0.666px;
        }

        @media (min-resolution: 168dpi) {
          width: 0.571px;
        }

        @media (min-resolution: 192dpi) {
          width: 0.5px;
        }
      }

      a{
        float:left;
        width:100%;

        @include media-breakpoint-up(lg) {
          &:hover{
            h2, .h2, h3, .h3{
              text-decoration: underline;
            }
          }
        }
      }

      //&:last-child{
      &:nth-child(4n){
        &:before{
          content:none;
        }
      }
    }
  }
  article{
    position: relative; float:left;
    width:100%;
    padding-top:10px; padding-bottom:10px;

    picture{
      position: relative;
      margin-bottom:15px;
      @include aspect-ratio-after(581, 327.3);

      &.square{
        @include aspect-ratio-after(386.66, 386.66);
      }

      &:before{
        content:""; position: absolute; top:0; left:0; z-index: 2;
        width:100%; height:100%;
        border:2px solid transparent;
        @include prefix(transition, (all .3s ease-out), webkit moz o ms);
      }

      img{
        position: absolute; left:0; top:0; z-index: 1;
        width:100%; height:100%;
        object-fit: cover; object-position: center center;
      }
    }

    img{
      display: block;
      width:100%;
    }

    h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
      font-family: $font-aptly; font-size:35px; line-height: 40px; letter-spacing:-0.17px;
    }
    h2, .h2{
      font-size:calc(35px + 15 * ((100vw - 375px) / 617));  line-height:calc(40px + 15 * ((100vw - 375px) / 617));
      letter-spacing:-0.25px;

      @include media-breakpoint-up(lg) {
        font-size:50px; line-height: 55px;
      }
    }
  }
}
.article-list-section{
  > div{
    .article-list-wrapper {
      &:last-child{
        > div{
          &:after{
            display: none; content:none;
          }
          &:before{
            height:100%;
          }
          article{
            margin-bottom:0;
          }
        }
      }
    }
  }
  .article-list-wrapper{
    h2, .h2, h3, .h3{
      margin-bottom:10px;
      @include media-breakpoint-up(lg) {
        max-width:80%;
      }
    }
    p{
      font-size:calc(20px + 5 * ((100vw - 375px) / 617)); line-height:calc(25px + 5 * ((100vw - 375px) / 617)); font-family: $font-scotch; color:color(grey);

      @include media-breakpoint-up(lg) {
        font-size:25px; line-height: 30px;
      }
    }
    picture{
      position: relative;
      margin-bottom:15px;
      &:before{
        content:""; position: absolute; top:0; left:0;
        width:100%; height:100%;
        border:2px solid transparent;
        @include prefix(transition, (all .3s ease-out), webkit moz o ms);
      }
      h2, .h2, h3, .h3{
        align-self: center;
        font-size:calc(25px + 10 * ((100vw - 375px) / 617)); line-height: calc(30px + 10 * ((100vw - 375px) / 617)); font-family: $font-aptly; color:color(white); font-weight: 700; text-align: center;
        text-shadow: 0 3px 6px rgba(0,0,0,.16);

        @include media-breakpoint-up(lg) {
          font-size:35px; line-height: 40px;
        }
      }
    }
    > div{
      &:before{
        display: none; content:none;
        @include media-breakpoint-up(lg) {
          content:""; position: absolute; top:($grid-row-gap/2); right:0; display: block;
          width:1px; height:calc(100% - #{$grid-row-gap});
          background-color:color(grey-lighter);
        }

        @media (min-resolution: 105.6dpi) {
          width: 0.90px;
        }

        @media (min-resolution: 120dpi) {
          width: 0.75px;
        }

        @media (min-resolution: 144dpi) {
          width: 0.666px;
        }

        @media (min-resolution: 168dpi) {
          width: 0.571px;
        }

        @media (min-resolution: 192dpi) {
          width: 0.5px;
        }
      }
      &:after{
        content:""; position: absolute; bottom:0; left:($grid-column-gap/2/2); display: block;
        width:calc(100% - #{($grid-column-gap/2)} );
        border-top:0.001px solid color(grey-lighter);

        @include media-breakpoint-up(lg) {
          content:""; position: absolute; bottom:0; left:($grid-row-gap/2); display: block;
          width:100%;
          border-top:0.001px solid color(grey-lighter);
        }
      }
      article{
        margin-top:($grid-row-gap/2); margin-bottom:($grid-row-gap/2);
      }
      &:last-child{
        &:before{
          display: none; content:none;
        }
      }
    }
  }
}
.article-list-section{
  &.no-borders{
    .article-list-wrapper{
      > div{
        &:before, &:after{
          content:none; display: none;
        }
        picture{
          margin:0;
        }
        article{
          padding-top:6px; padding-bottom:0;
        }
      }
      &:last-child{
        article{
          padding-top:6px; padding-bottom:0;
        }
      }
      &.image-grid{
        a{
          &.bg-image{
            margin-top:16px;
          }
        }
      }
    }
  }
}

.intro-section{
  position: relative;

  article{
    display: flex; align-items: flex-end;
  }
  .article-content{
    position: relative;
    width:100%;

    p, ul, ol {
      &:last-child{
        margin-bottom:0;
      }
    }

    .hotel-category {
      margin-bottom:5px; margin-left:0;
      list-style: none;
    }
  }
  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
    padding-left:calc(59px + 28 * ((100vw - 375px) / 1065));
    line-height: calc(44px + 28 * ((100vw - 375px) / 1065))!important;

    @include media-breakpoint-up(xl-1440) {
      padding-left:87px;
      line-height:72px!important;
    }
  }
  .section-icon{
    position: absolute; top:0; left:0;
    text-align: left; font-size:44px; line-height: 44px;

    @include media-breakpoint-up(sm-375) {
      font-size: calc(44px + 28 * ((100vw - 375px) / 1065)); line-height: calc(44px + 28 * ((100vw - 375px) / 1065));
    }
    @include media-breakpoint-up(xl-1440) {
      font-size:72px; line-height:72px;
    }
  }
  .container{
    position: relative;
    padding-bottom:$section-margin;
    &:after{
      content:""; position: absolute; bottom:0; left:8px; display: block;
      width:calc(100% - 16px); height:2px;
      border-top:0.001px solid color(grey-lighter);
    }
  }
  &.no-borders{
    .container{
      padding-bottom:5px;

      &:after{
        display: none; content: none;
      }
    }
  }
}

.article-icon-box{
  display: block; z-index: 2; display: flex; flex-wrap: wrap; flex-direction: column;
  width:100%;
  i{
    align-self: center;
    font-size:40px; line-height: 40px; text-align: center;
    width:40px; height:40px;
    border-radius: 50%; margin-bottom:5px;
  }
  span{
    .btn{
      align-self: center;
    }
  }
}
.article-list-wrapper{
  .article-icon-box{
    position: absolute; top:50%; left:50%;
    padding: 0 20px;
    @include prefix(transform, translate(-50%, -50%), webkit moz o ms);
    i{
      background-color:rgba(0,0,0,.4);
      color:color(white);
    }
    span{
      color:color(white);
      .btn{
        color:color(white);
      }
    }
  }
}
.icon-boxes{
  .article-icon-box{
    .btn-round{
      align-self: center;
    }
  }
  &.icon-boxes-small{
    position: relative;
    overflow:hidden;
    > div{
      margin-bottom: 25px; padding-top: 15px; padding-bottom:15px;

      h2, .h2, h3, .h3{
        font-size:calc(25px + 10 * ((100vw - 375px) / 617)); line-height: calc(30px + 10 * ((100vw - 375px) / 617)); font-family: $font-aptly; font-weight: 700; text-align: center;

        @include media-breakpoint-up(lg) {
          font-size:35px; line-height: 40px;
        }
      }
      .btn-round{
        background:transparent;
      }
    }

    &:last-child{
      >div:nth-child(3), >div:nth-child(4){
        margin-bottom:0;
      }
      @include media-breakpoint-up(lg) {
        > div{
          margin-bottom: 0;
        }
      }
      &:after{
        content:none; display: none;
      }
    }

    &:after{
      content:""; position: absolute; bottom:9px; left:($grid-column-gap/2/2); display: block;
      width:calc(100% - #{($grid-column-gap/2)} ); height:1px;
      background-color:color(grey-lighter);

      @include media-breakpoint-up(lg) {
        content:""; position: absolute; bottom:9px; left:($grid-row-gap/2); display: block;
        width:100%;
        border-top:0.001px solid color(grey-lighter);
      }
    }
  }
}

.list-image-section{
  article{
    > h2, > .h2, > h3, > .h3{
      margin-bottom:10px;
    }
  }
  picture{
    float:right;
    max-width:175px;
  }
}

.article-list-section {
  .app-button{
    float:left;
    margin-right:20px;
    width:100%; max-width:175px;

    &:last-child{
      margin-right:0;
    }
    @include media-breakpoint-up(lg) {
      margin-right:30px;
    }

    article{
      margin:0; padding:0;
      picture{
        position: relative;
        margin:0; padding:0; border-radius:20px; overflow: hidden;
        &:before{
          position: absolute; top:0; left:0; content: ""; display: block;
          width:100%; height:100%;
          background-color:rgba(0,0,0,.5);
        }
      }
    }
  }
}

.hotel-category {
  li {
    width:auto;
    margin-right:5px; padding-left:0!important;

    &:before {
      content:none!important;
    }

    i {
      width:25px; line-height:25px;
      font-size:25px; line-height:25px;

      @include media-breakpoint-up(md) {
        width:32px; line-height:32px;
        font-size:32px; line-height:32px;
      }
    }
  }
}

.booking-btn {
  display:block; float:left;
  opacity:0.75;
  @include prefix(transition, all .3s ease-out, webkit moz o ms);

  @include media-breakpoint-up(lg) {
    &:hover{
      opacity:1;
    }
  }
}


// accordion
.accordion-wrapper{
  float:left; display: block;
  width:100%;

  .accordion-heading{
    float:left; display: block; position: relative;
    width:100%;
    padding:20px 20px 20px 54px;
    font-size:calc(25px + 5 * ((100vw - 375px) / 617)); line-height: calc(30px + 0 * ((100vw - 375px) / 617));
    color:color(grey); font-family: $font-scotch;
    @include prefix(transition, (all .3s ease-out), webkit moz o ms);

    @include media-breakpoint-up(lg) {
      padding:20px 50px 20px 54px;
      font-size:30px; line-height: 30px;
    }

    &:before{
      z-index: 1; position: absolute; top:0; left:0; opacity:.1; content:"";
      width:100%; height:100%;
    }
    span{
      position: relative; z-index: 2;
    }
    i{
      position: absolute; z-index: 2; display: inline-block; top:24px; left:20px;
      font-size:calc(20px + 5 * ((100vw - 375px) / 617)); line-height: calc(20px + 5 * ((100vw - 375px) / 617));
      color:color(grey);
      @include prefix(transform, rotate(0), webkit moz o ms);
      @include prefix(transition, (all .3s ease-out), webkit moz o ms);

      @include media-breakpoint-up(lg) {
        top:22px;
        font-size:25px; line-height: 25px;
      }
    }
  }
  .accordion-panel{
    float:left; display: none; opacity:0;
    width:100%;
    padding:10px 20px 20px 54px;
    font-size:calc(20px + 5 * ((100vw - 375px) / 617)); line-height: calc(25px + 5 * ((100vw - 375px) / 617));
    font-size:25px; line-height: 30px; font-family: $font-scotch; color:color(grey);
    @include prefix(transition, (all .3s ease-out), webkit moz o ms);

    @include media-breakpoint-up(lg) {
      font-size:25px; line-height: 30px;
    }
    *{
      position: relative; z-index: 2;
    }
    p, li{
      font-family: $font-scotch; color:color(grey);
      font-size:calc(20px + 5 * ((100vw - 375px) / 617)); line-height: calc(25px + 5 * ((100vw - 375px) / 617));

      @include media-breakpoint-up(lg) {
        font-size:25px; line-height: 30px;
      }

      &:last-child{
        margin-bottom:0;
      }
    }
    ul{
      display:inherit;
      list-style: inherit;
      margin-left:54px; margin-bottom:1em;
      li{
        display: list-item;
      }
    }
    ol{
      display:inherit;
      list-style: auto; list-style-type: decimal;
      margin-left:54px; margin-bottom:1em;
      li{
        display: list-item;
      }
    }
  }
  .accordion-panel-wrapper{
    float: left;
    width: 100%;
    margin-bottom:20px;

    &.active{
      .accordion-heading{
        &:before{
          opacity:.2;
        }
        i{
          @include prefix(transform, rotate(-45deg), webkit moz o ms);
        }
      }
      .accordion-panel{
        display: block; opacity:1; position: relative;
        &:before{
          z-index: 1; position: absolute; top:0; left:0; opacity:.1; content:"";
          width:100%; height:100%;
        }
      }
    }
  }
}

// iOutdoor tabs
.tabs-wrapper{
  .tabs-heading-wrapper{
    float:left; display: block;
    width:100%;
    font-size: 0;
    border-left:1px solid color(grey-lighter);
    .scroll-wrapper{
      flex-direction: row; display: flex; overflow-x: auto; overflow-y: hidden;
      white-space: nowrap;
      width:calc(100% + 20px);
    }

    a{
      float:left; display:flex; position: relative; justify-content:center; align-items:center;
      min-width:142px; min-height:60px;
      padding:10px 24px; border-left:1px solid color(grey-lighter); margin:0;
      text-align: center; color:color(grey-light); font-size:15px; line-height:20px; text-transform: uppercase; white-space:normal;

      &:before{
        position: absolute; top:0; left:0; content:""; z-index: 1; opacity:1;
        width:100%; height:100%;
        background-color:color(grey-lightest);
        @include prefix(transition, (all .3s ease-out), webkit moz o ms);
      }
      &:after{
        position: absolute; top:0; left:0; content:""; z-index: 2; opacity:0;
        width:100%; height:100%;
        @include prefix(transition, (all .3s ease-out), webkit moz o ms);
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          &:before {
            opacity:0;
          }
          &:after {
            opacity:.1;
          }
        }
      }

      &:first-child{
        border-left:0;
      }
      &:last-child{
        border-right:1px solid color(grey-lighter);
      }
      &.active{
        color:color(white);

        &:after {
          opacity:1!important;
        }
      }

      span{
        position: relative; z-index: 3; display: block;
      }
    }
  }
  > .tab-content-wrapper {
    > .tab-content{
      display: none;
      &.active{
        display: block;
      }

      .article-section{
        margin-top:20px; margin-bottom:10px;
      }
    }
  }
}

.outdoor-results-wrapper{
  padding:20px 0 0 0;
  &:after{
    content:""; position:absolute; bottom:-3px; left:0; display:block; z-index:2;
    width:100%; height:5px;
    background-color:color(white);
  }

  .row{
    &:after{
      content:""; position: absolute; top:0; right:-10px; display: block;
      width:10px; height:calc(100%);
      background-color:color(white);
    }
    > div{
      position: relative;
      margin:10px 0;

      &:before{
        display: none; content:none;
        @include media-breakpoint-up(md) {
          content:""; position: absolute; top:-($grid-row-gap/2); right:0; display: block;
          width:1px; height:calc(100% + #{$grid-row-gap});
          background-color:color(grey-lighter);

          @media (min-resolution: 105.6dpi) {
            width: 0.90px;
          }

          @media (min-resolution: 120dpi) {
            width: 0.75px;
          }

          @media (min-resolution: 144dpi) {
            width: 0.666px;
          }

          @media (min-resolution: 168dpi) {
            width: 0.571px;
          }

          @media (min-resolution: 192dpi) {
            width: 0.5px;
          }
        }
      }
      &:after{
        content:""; position: absolute; bottom:-10px; left:($grid-column-gap/2/2); display: block;
        width:calc(100% - #{($grid-column-gap/2)} );
        border-top:0.001px solid color(grey-lighter);

        @include media-breakpoint-up(md) {
          content:""; position: absolute; bottom:-10px; left:($grid-row-gap); display: block;
          width:calc(100% - #{($grid-row-gap*2)} );
          border-top:0.001px solid color(grey-lighter);
        }
      }
    }
  }
  article{
    height: 100%;
    > a {
      display: flex; flex-direction: column;
      height: 100%;
    }
  }
  picture{
    position: relative;

    i{
      position: absolute; top:0; left:0;
      width:20px; height:20px;
      color:color(white); text-align: center; line-height: 20px; font-size:16px;
      padding:0;

      &:before{
        position: relative; z-index: 2;
        color:color(white);
      }

      &:after{
        position: absolute; top:0; left:0; content:""; z-index: 1;
        width:100%; height:100%;
      }
    }
  }

  .outdoor-result-info-wrapper{
    display: block; float:left; flex: 1 1 auto;
    padding:10px 10px 18px 10px;
    background-color:color(grey-lightest);
    color:color(grey);
    min-height: 300px;

    h4, .h4{
      font-size:20px; line-height:25px;
      margin-bottom:10px;
    }
    h3, .h3{
      font-family: $font-aptly; font-size:calc(30px + 15 * ((100vw - 375px) / 617)); line-height:calc(35px + 15 * ((100vw - 375px) / 617)); letter-spacing:-0.17px;
      margin-bottom:20px;

      @include media-breakpoint-up(lg) {
        font-size:45px; line-height:50px;
      }
    }
    .difficulty-level{
      float:left; position: relative; display: inline-block;
      padding:4px 6px; margin-right:10px; margin-bottom:10px; border:0;
      color:color(white); font-size:15px; line-height:18px;

      &.difficulty-red{
        background-color:#272727;
      }

      &.difficulty-yellow{
        background-color:#c00;
      }

      &.difficulty-green{
        background-color:#06c;
      }
    }
    .outdoor-result-data{
      margin-top:10px;

      li{
        float:left; display: block;
        width:100%;
        padding:10px 0;
        border-top:1px solid color(grey-lighter);

        > div{
          position:relative;
          float:left;
          width:50%;
          font-size:20px; line-height:24px;
          padding-left:40px;

          i {
            position:absolute; left:0; top:0;
            width:24px; height:24px;
            font-size:24px; line-height:24px;
          }
        }
      }
    }
  }
}
