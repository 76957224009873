footer{
  float:left;
  width:100%;
  font-family: $font_scotch;
  padding-bottom: 50px;

  p{
    font-size:15px; line-height: 20px; color:color(grey);
  }

  h2, .h2{
    font-size: 20px; line-height: 25px; font-family: $font_scotch; color:color(grey);
    padding-bottom:15px;

    @include media-breakpoint-up(lg) {
      padding-bottom:0;
      font-size: 25px; line-height: 30px;
    }
  }

  &.border-section{
    margin-top:0; padding-top:0; padding-bottom: 50px;

    &:before{
      content:none;
    }
    .row-border{
      position: relative; align-items: center;
      padding-top:$section_margin; margin-top:$section_margin;

      &:before{
        content:""; position: absolute; top:0; left:50%; display: block;
        width:100vw;
        border-top:0.001px solid color(grey-lighter);
        @include prefix(transform, translateX(-50%), webkit moz o ms);
      }
      &.row-top{
        align-items: flex-start;
      }
    }
  }
  .form-newsletter{
    margin-bottom:-10px;
    @include media-breakpoint-up(lg) {
      margin-bottom:0;
    }
    input{
      width:100%; max-width:220px;
      margin-right:10px; margin-bottom:10px;
      @include media-breakpoint-up(lg) {
        max-width:380px;
        margin-right: 20px; margin-bottom:0;
      }
    }
    button{
      margin-bottom:10px;
      @include media-breakpoint-up(lg) {
        margin-bottom:0;
      }
    }
  }
  .logo-list{
    display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-evenly;
    margin-bottom:-10px;
    @include media-breakpoint-up(lg) {
      flex-wrap: inherit;
      margin-bottom:0;
    }
    li{
      justify-content: flex-start; display: flex; align-items: flex-start;
      width:auto;
      margin-right:10px; margin-bottom:10px;

      @include media-breakpoint-up(lg) {
        justify-content: flex-end; align-items: center;
        width:100%;
        margin-right:0; margin-bottom:0;
      }

      i{
        height:45px;
        @include media-breakpoint-up(lg) {
          font-size:67px;
        }

        &.icon-ascona-cleanandsafe, &.icon-ascona-swisstainable {
          font-size:48px;
          @include media-breakpoint-up(lg) {
            font-size:67px;
          }
        }
        &.icon-ascona-quality {
          font-size:61px;
          @include media-breakpoint-up(lg) {
            font-size:88px;
          }
        }
        &.icon-ascona-family {
          font-size:59px;
          @include media-breakpoint-up(lg) {
            font-size:85px;
          }
        }
        &.icon-ascona-wellness {
          font-size:58px;
          @include media-breakpoint-up(lg) {
            font-size:83px;
          }
        }
        &:before{
          color:color(grey);
          @include prefix(transition, (all .3s ease-out), webkit moz o ms);
        }
      }

      img {
        height:48px; width:auto;
        opacity:1;
        @include prefix(transition, all .2s ease-out, webkit moz o ms);

        @include media-breakpoint-up(lg) {
          height:67px;
        }

        &.hover {
          position:absolute; left:0; top:0;
          opacity:0;
          background-color:color(white);
          z-index:1;
        }
        &.logo-cleanandsafe, &.logo-swisstainable {
          height:48px;

          @include media-breakpoint-up(lg) {
            height:67px;
          }
        }
        &.logo-quality {
          height:61px;

          @include media-breakpoint-up(lg) {
            height:88px;
          }
        }
        &.logo-family {
          height:59px;

          @include media-breakpoint-up(lg) {
            height:85px;
          }
        }
        &.logo-wellness {
          height:58px;

          @include media-breakpoint-up(lg) {
            height:83px;
          }
        }
      }

      a{
        position:relative;
        color:color(grey);

        @include media-breakpoint-up(lg) {
          &:hover {
            img {
              &.hover {
                opacity:1;
              }
            }
          }
        }
      }
    }
  }
}
