// Color variables

$color-list: (
  white:#FFFFFF,
  black:#000000,
  grey:#707070,
  grey-light:#999999,
  grey-lighter:#CCCCCC,
  grey-lightest:#F5F5F5,

  ascona01:#419FAD,
  ascona02:#5DC2AD,
  ascona03:#31AD65,
  ascona04:#DD26B3,
  ascona05:#ADB92D,
  ascona06:#00BEf0,
  ascona07:#E68C10,
  ascona08:#73D518,
  ascona09:#D54C1A,
  ascona10:#D3125D,
  ascona11:#7262D0,
  ascona12:#477ABA,

  layer:#ECF1F8,

  red:#E60404,
  yellow:#ffc107,
  green:#03C938
);

// Color class loop

@each $color, $value in $color-list {
  .txt-#{$color} { color:$value!important; }
  .bkg-#{$color} { background-color:$value!important; }
}

// Color var function

@function color($color) {
  @return map-get($color-list, $color);
}
