/* Prefixes */

@mixin prefix($property, $value, $prefixes: ()) {
  @each $prefix in $prefixes {
    #{'-' + $prefix + '-' + $property}: $value;
  }
  #{$property}: $value;
}

@mixin bglayer() {
  background-color:rgba( 0,0,0,.20 );
}

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
}

@mixin aspect-ratio-after ($width, $height) {
  position: relative;
  &:after {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
}

@mixin aspect-ratio-padding($width, $height) {
  padding-top: ($height / $width) * 100%;
}


$grid-column-gap: 30px;
$grid-row-gap: 20px;
// desktop
$section_margin: 15px;
// mobile
// $section_margin_mobile: 10px;

/* --- */

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  sm-320: 320px,
  sm-360: 360px,
  sm-375: 375px,
  sm-428: 428px,
  sm-480: 480px,
  sm-640: 640px,
  md: 768px,
  md-800: 800px,
  lg: 992px,
  xl: 1200px,
  xl-1440: 1440px,
  xxl: 1600px,
  xxl-1920: 1920px,
  over: 1921px
);

$container-max-widths: (
  sm: 576px,
  sm-320: 320px,
  sm-360: 360px,
  sm-375: 375px,
  sm-428: 428px,
  sm-480: 480px,
  sm-640: 640px,
  md: 768px,
  md-800: 800px,
  lg: 992px,
  xl: 1200px,
  xl-1440: 1440px,
  xxl: 1600px,
  xxl-1920: 1920px,
  over: 1921px
);
