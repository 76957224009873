.article-section{
  article{
    display: flex; flex-wrap: wrap;

    a{
      @include media-breakpoint-up(lg) {
        &:hover{
          text-decoration: underline;
        }
      }
    }

    h2, .h2{
      font-size: 35px; line-height: 40px; font-family: $font-scotch;

      @include media-breakpoint-up(lg) {
        font-size: 60px; line-height: 67px;
      }
    }
    h3, .h3{
      font-size:35px; line-height:50px; font-family: $font-scotch;

      @include media-breakpoint-up(lg) {
        font-size:35px; line-height:50px;
      }
    }

    p{
      color: color(grey); font-size: 20px; line-height: 26px;

      @include media-breakpoint-up(lg) {
        font-size: 25px; line-height: 30px;
      }
    }

    ul, ol {
      li {
        position:relative;
        padding-left:20px;
        color:color(grey); font-size:20px; line-height:26px;

        @include media-breakpoint-up(lg) {
          font-size: 25px; line-height:30px;
        }
      }
    }
    .info-box{
      ul {
        li {
          padding-left:0;
          &:before {
            content:none; display: none
          }
        }
      }
    }
    ul {
      li {
        &:before {
          content:'•';
          position:absolute; left:0; top:0;
          color:color(grey); font-size:20px; line-height:26px;

          @include media-breakpoint-up(lg) {
            font-size: 25px; line-height:30px;
          }
        }
      }
    }
    ol {
      counter-reset: my-counter;
      li {
        counter-increment: my-counter;
        &:before {
          content:counter(my-counter) ". ";
          position:absolute; left:0; top:0;
          color:color(grey); font-size:16px; line-height:26px;

          @include media-breakpoint-up(lg) {
            font-size: 16px; line-height:30px;
          }
        }
      }
    }

    &.style-fullwidth{
      display: block;

      .video-wrapper{
        margin-top: 5px;
      }

      .article-content{
        display: block;
      }
    }

    &.style-1{
      flex-direction: column;
      @include media-breakpoint-up(lg) {
        flex-direction: row;
      }
      .article-content{
        flex: 1;
        @include media-breakpoint-up(lg) {
          margin-right:30px;
        }

        > .first-element{
          margin-bottom:20px;
        }
      }

      aside{
        flex:1;
        width:100%;
        margin-top:10px;
        @include media-breakpoint-up(lg) {
          flex-direction: column;
          max-width:380px;
        }

        > *{
          margin-bottom:20px;

          &:last-child{
            margin-bottom:0;
          }
        }

        figure{
          width:100%;
          //max-width:380px;
          //margin-right:30px;

          figcaption{
            margin-left:30px;

            @include media-breakpoint-up(lg) {
              margin-left:0;
            }
          }

          @include media-breakpoint-up(lg) {
            margin-right:0;
          }
        }
      }
    }

    &.style-2{
      .article-content{
        @include media-breakpoint-up(lg) {
          p{
            max-width:80%;
          }
          h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
            max-width:60%;
          }
        }
        figure{
          figcaption{
            margin-left:30px;
          }
        }
      }
    }

    &.style-3{
      .article-content{
        display: flex; flex-direction: row; flex-wrap:wrap;
        @include media-breakpoint-up(lg) {
          display: block; flex-direction: column;
        }

        p, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
          display: inline-block;
        }

        figure{
          clear: both;
          width:100%;

          @include media-breakpoint-up(lg) {
            margin-right:0;
            max-width:380px;
          }

          &.align-left{
            float:left; order:2; display: flex; flex-direction: column;
            @include media-breakpoint-up(lg) {
              display: block;
            }

            &+p{
              order:1;
              float:left;
              width: 100%;

              @include media-breakpoint-up(lg) {
                display: block;
                max-width: calc(100% - 380px - 30px);
                margin-left: 30px;
              }
            }
          }

          &.align-right{
            float:left; order:2; display: flex; flex-direction: column;
            @include media-breakpoint-up(lg) {
              display: block; float:right;
            }

            &+p{
              float:left; order:1;
              width: 100%;

              @include media-breakpoint-up(lg) {
                display: block;
                max-width: calc(100% - 380px - 30px);
                margin-right: 30px;
              }
            }
          }
          figcaption{
            margin-left:20px;
          }
        }
      }
    }

    .article-content{
      display: flex; flex-direction: column;

      h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
        margin-bottom:15px;
      }
      p, ul, ol {
        margin-bottom:15px;

        /*&:last-child{
          margin-bottom:0;
        }*/
      }
    }
    aside{
      display: flex; flex-direction: column;
    }
  }
  .hotel-info-wrapper{
    .article-content{
      h3, .h3{
        font-size:calc(25px + 10 * ((100vw - 375px) / 617)); line-height: calc(30px + 20 * ((100vw - 375px) / 617));

        @include media-breakpoint-up(lg) {
          font-size:35px; line-height: 50px;
        }
      }
    }
  }
  .video-wrapper-big{
    article{
      display: block;
    }
  }
}

.video-wrapper, .iframe-wrapper{
  position: relative; float:left; display: block;
  width:100%;
  padding-bottom:56.25%;

  iframe{
    position: absolute; top:0; left:0;
    width:100%; height:100%;
  }
}


figure{
  margin-bottom:20px;
  figcaption{
    float:left; display: block;
    font-size:15px; line-height: 20px; font-family: $font-scotch; color:color(grey); font-style: italic;
    margin-top:10px;

    @include media-breakpoint-up(lg) {
      font-size:20px; line-height: 25px;
    }
  }
}


.animated-teaser-wrapper{
  position: relative; float:left;
  width:100%;

  > picture{
    position: relative; z-index:1;
  }

  .animated-content-wrapper{
    position: absolute; top:50%; left:20px; right:20px; display: block; z-index: 2;
    width:calc(100% - 40px);
    @include prefix(transform, translateY(-50%), webkit moz o ms);

    @include media-breakpoint-up(lg) {
      left:50%;
      max-width:1200px;
      @include prefix(transform, translate(-50%, -50%), webkit moz o ms);
    }
    @include media-breakpoint-up(lg) {
      a{
        &:hover{
          text-decoration: underline;
        }
      }
    }
    ul{
      display: block; position: relative; float: left;
      margin:0;

      li{
        display: block; float:left; position: relative;
        width:100%;
        margin:0 0 20px 0; padding:15px;
        background-color:rgba(255,255,255,.9);
        color:color(grey);

        h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
          font-size:25px; line-height: 30px;
          @include media-breakpoint-up(lg) {
            font-size:45px; line-height: 50px;
          }
        }
        p{
          color:color(grey);
          margin:0; padding:0;
        }

        &:last-child{
          margin-bottom:0;
        }
        /* DELAYS
        &:nth-child(1){
          transition-delay:.25s;
        }

        &:nth-child(2){
          transition-delay:.50s;
        }

        &:nth-child(3){
          transition-delay:.75s;
        }

        &:nth-child(4){
          transition-delay:1s;
        }

        &:nth-child(5){
          transition-delay:1.25s;
        }

        &:nth-child(6){
          transition-delay:1.50s;
        }

        &:nth-child(7){
          transition-delay:1.75s;
        }

        &:nth-child(8){
          transition-delay:2s;
        }*/
      }
    }
  }
}

[data-inviewport] {
  transition: .6s;
  bottom:-50px;
  opacity: 0;
}
[data-inviewport].fade-in-top {
  bottom:0;
  opacity: 1;
}

.article-section{
  article{
    .info-box{
      p{
        font-size:20px; line-height: 26px;
        margin-bottom:10px;
      }
    }
  }
}
.info-box{
  float:left; position: relative;
  border:2px solid transparent; padding:14px;

  &:after{
    position: absolute; top:0; left:0; content:""; display: block; z-index: 1; opacity: .1;
    width:100%; height:100%;
  }

  *{
    position: relative; z-index: 2;
  }
  h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
    font-size:25px; line-height: 30px; font-family: $font-scotch;
    margin-bottom:10px;
  }
  p{
    font-size:20px; line-height: 26px;
    margin-bottom:10px;
  }
  img{
    width:auto;
    float:none;
    margin:0 auto;
  }
  .arrow-list{
    font-size:20px; line-height: 25px;

    a{
      font-size:20px; line-height: 25px;
      padding-left: 25px;

      &:hover{
        text-decoration: none;
      }

      &:before{
        top:2px;
        font-size:20px; line-height: 20px;
      }
    }
  }

  
  ul{
    &:not(.arrow-list){
      display:inherit;
      list-style: inherit;
      margin-left:20px; margin-bottom:1em;
      li{
        display: list-item;
        font-size:20px; line-height: 26px;
      }
    }
  }
  ol{
    &:not(.arrow-list){
      display:inherit;
      list-style: auto; list-style-type: decimal;
      margin-left:0; margin-bottom:1em;
      li{
        display: list-item;
        font-size:20px; line-height: 26px;
        &:before{
          top:-2px;
        }
        &::marker{
          display: none; content:none;
        }
      }
    }
  }
}

.article-content{
  .table-responsive{
    float:left;
    width:100%;
    margin-bottom:15px;
    table{
      float:left;
      width:100%;

      thead{
        tr{
          th{
            border-right:0;
            text-transform: uppercase;

            i{
              display: block;
              margin-top:3px;
            }
          }
        }
      }
      tr{
        &:last-child{
          border-bottom:0;
        }
        td, th{
          &:last-child{
            border-right:0;
          }
        }
      }

      td, th{
        padding:6px 8px;
        font-size:16px; line-height:21px; font-family:$font_scotch; text-align: center; white-space: nowrap;

        @include media-breakpoint-up(sm-375) {
          font-size:calc(16px + 4 * ((100vw - 375px) / 1065)); line-height:calc(21px + 4 * ((100vw - 375px) / 1065));
        }
        @include media-breakpoint-up(xl-1440) {
          font-size:20px; line-height:25px;
        }
      }
      td{
       color:color(grey);
       &.img{
         img{
           float: none;
           margin: 0 auto;
           width:13px; height:13px;
         }
       }
      }
      th{
         color:color(black);
         &:first-child{
           text-align: left;
         }
      }
      tr{
        td{
          &:first-child{
             color:color(black); text-align: left; white-space: normal;
          }
        }
      }
    }
  }
}
.article-content{
  table{
    &.legend{
      display: block; float:left;
      tr{
        display: block; float:left;
        td, th{
          display: block; float:left;
          width:100%;
          text-align: left;
          i{
            margin-right:6px;
            color:color(black);
          }
          img{
            float:left;
            margin: 6px 14px 0 3px;
            width:13px; height:13px;
          }
        }
        th, td, td:first-child{
          color:color(grey);
          &:before{
            opacity:0;
          }
        }
      }
    }
  }
}
