@include media-breakpoint-up(lg) {
  html, #main-header{
    width: 100vw;
  }
  body{
    overflow-x: hidden;
  }
}
#main-header{
  .container{
    padding-right:8px; padding-left:8px;
    .row {
      margin-right:-8px; margin-left:-8px;

      > div {
        padding-right:8px; padding-left:8px;
      }
    }
  }
}
.slider-section{
  .slider-arrow-wrapper .container{
    padding-right:0; padding-left:0;
    @include media-breakpoint-up(lg) {
      padding-right:8px; padding-left:8px;
    }
    .row {
      margin-right:0; margin-left:0;
      @include media-breakpoint-up(lg) {
        margin-right:-8px; margin-left:-8px;
      }

      > div {
        padding-right:0; padding-left:0;
        @include media-breakpoint-up(lg) {
          padding-right:8px; padding-left:8px;
        }
      }
    }
  }
}

.stories-section{
  .container {
    .row {
      margin-right:-15px; margin-left:-15px;

      > div {
        padding-right:15px; padding-left:15px;
      }
    }
  }
}

.tab-content{
  .article-section {
    .container {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.container {
  width:100%; max-width:1208px;
  padding-right:20px; padding-left:20px;
  @include media-breakpoint-up(xl) {
    padding-right:8px; padding-left:8px;
  }

  .row {
    margin-right:-8px; margin-left:-8px;

    > div {
      padding-right:8px; padding-left:8px;
    }

    &.image-grid{
      margin-right:-6px; margin-left:-6px;
      @include media-breakpoint-up(lg) {
        margin-right:-8px; margin-left:-8px;
      }
      > div{
        padding-left:6px; padding-right:6px; margin-bottom: 12px;
        .bg-image:nth-child(2){
          margin-top: 12px;
          @include media-breakpoint-up(lg) {
            margin-top: 16px;
          }
        }
        @include media-breakpoint-up(lg) {
          padding-left:8px; padding-right:8px; margin-bottom: 16px;
        }
      }
      &.article-list-wrapper{
        > div{
          padding-left:6px; padding-right:6px; margin-bottom: 0;
          .bg-image:nth-child(2){
            margin-top: 12px;
            @include media-breakpoint-up(lg) {
              margin-top: 16px;
            }
          }
          @include media-breakpoint-up(lg) {
            padding-left:8px; padding-right:8px; margin-bottom: 0;
          }
        }
      }
    }

    &.gutter-30 {
      margin-right:-15px; margin-left:-15px;

      > div {
        padding-right:15px; padding-left:15px;
      }
    }
  }
}

.content-wrapper {
  position:relative;
  width:100%;
  overflow:hidden;
}

section, aside {
  position:relative; float:left; z-index:1;
  width:100%;
}

a{
  &.anchor{
    position: relative; bottom: 85px;
  }
}

.border-section{
  position: relative;
  padding:$section_margin 0 0 0; margin:$section_margin 0 0 0;

  &:before{
    content:""; position: absolute; top:0; left:0; display: block;
    width:100%;
    border-top:0.001px solid color(grey-lighter);
  }
}
.tab-content{
  &.hotels{
    .border-section{
      padding-bottom:15px;
      &:after{
        display:none;
      }
    }
  }
  .tab-content-wrapper{
    padding:20px 0 5px 0; margin-top:0;
  }
  .border-section{
    padding:20px 0; margin-top:0;

    &:before{
      display: none
    }
    &:after{
      content:""; position: absolute; bottom:0; left:0; display: block;
      width:100vw;
      border-top:0.001px solid color(grey-lighter);
      transform: translateX(-50%);
      margin-left: 50%;
    }
  }
}
.i-outdoor-tabs{
  &.border-section{
    padding:1px 0 0 0; margin-top:45px;

    .tabs-heading-wrapper{
      position: relative;
      &:after{
        content:""; position: absolute; bottom:-1px; left:0; display: block;
        width:100vw;
        border-top:0.001px solid color(grey-lighter);
        transform: translateX(-50%);
        margin-left: 50%;
      }
    }

    .btn-text{
      padding-right:0; padding-left: 7px;
    }
  }
}
.border-section-inner{
  position: relative;
  padding:$section_margin 0 0 0; margin:$section_margin 0 0 0;

  &:before{
    content:""; position: absolute; top:0; left:50%; display: block;
    width:calc(100% - 40px);
    max-width:1208px;    
    border-top:0.001px solid color(grey-lighter);
    @include prefix(transform, translateX(-50%), webkit moz o ms);
  }
}
.breadcrumb-section{
  position: relative;
  padding:($section_margin*2) 0 $section_margin 0; margin:$section_margin 0 $section_margin 0;

  &:before{
    content:""; position: absolute; top:$section_margin; left:0; display: block;
    width:100%;
    border-top:0.001px solid color(grey-lighter);
  }

  &:after{
    content:""; position: absolute; bottom:$section_margin; left:0; display: block;
    width:100%;
    border-top:0.001px solid color(grey-lighter);
  }
}

.overflow-visible {
  overflow:visible;
}

ul, ol {
  display:block; float:left;
  width:100%;
  padding:0; margin:0;
  list-style:none;

  li {
    display:block; float:left;
    width:100%;
  }
}

img, figure, picture {
  display:block; float:left;
  width:100%; height:auto;
  margin:0;
}

a {
  text-decoration:none;
}

.breadcrumb{
  font-size:15px; line-height: 20px; color:color(grey); font-family: $font-vista;
  ul{
    display: block; float:left;
    margin:0; padding:0;
    width:100%;

    li{
      float:left; position: relative; display: none;
      width:auto;
      margin-right: 15px;


      @include media-breakpoint-up(lg) {
        display: block;
      }
      &:nth-last-child(2){
        display: block;
        &:before{
          content:"… /"; left:-30px; display: block; float: left;
          color:color(grey); margin-right:7px;
          @include media-breakpoint-up(lg) {
             display: none;
          }
        }
        &:after{
          content:"/ …"; right:-30px; display: block;
          @include media-breakpoint-up(lg) {
            content:"/"; right:-10px;
          }
        }
      }

      &:after{
        content:"/"; position: absolute; top:0; right:-10px;  display: block;
        color:color(grey);
      }
      &:last-child{
        &:after{
          content:none;
        }
      }
    }
  }

  a{
    color:color(grey); font-size:15px; line-height: 20px;
    @include media-breakpoint-up(lg) {
      &:hover{
        text-decoration: underline;
      }
    }
  }
}

.events-section{
  z-index: 2;
}

.hero-content .hero-icon {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(-10px);
  }
  40% {
    transform: translateY(10px);
  }
  60% {
    transform: translateY(0px);
  }
}

.search-wrapper{
  .filter-blocker{
    height:100%;
  }
  form{
    .row{
      >div{
        &:last-child{
          text-align:right;

          .btn-text, .btn-round{
            float:none;
          }
        }
      }
    }
  }
}
