body {
  &.modal-open {
    overflow:hidden;
  }
}

.modal {
  &.auto {
    align-items:center;

    &.show {
      display:flex!important;
    }
  }
  .modal-dialog {
    margin-left:auto; margin-right:auto;
  }
  &.advent {
    .modal-dialog {
      max-width:320px;

      @include media-breakpoint-up(lg) {
        max-width:900px;
      }

      .modal-content {
        position:relative;
        display:flex; flex-direction:column;
        border:0 none; border-radius:0;
        background-color:var(--main-color);/*color(ascona12)*/;

        @include media-breakpoint-up(lg) {
          flex-direction:row;
        }

        .close {
          position:absolute; right:0; top:0;
          width:42px; height:42px;
          border:0 none; padding:0; margin:0;
          background-color:transparent;

          span {
            position:relative;
            display:block; float:left;
            width:42px; height:42px;
          }
          i {
            position:absolute; left:50%; top:50%;
            width:22px; height:22px;
            font-size:22px; line-height:22px; color:color(white);
            @include prefix(transform, translate(-50%,-50%), webkit moz o ms);
            background-color:rgba(255,255,255,.10);
          }

          @include media-breakpoint-up(lg) {
            right:18px; top:18px;
            width:35px; height:35px;

            span {
              width:35px; height:35px;
            }
            i {
              width:35px; height:35px;
              font-size:35px; line-height:35px;
            }
          }
        }
        .image {
          display:block; float:left;
          width:100%;

          @include media-breakpoint-up(lg) {
            width:50%;
          }
        }
        .content {
          display:flex; flex-direction:column; align-items:center; justify-content:center;
          width:100%;
          padding:28px;
          text-align:center;

          h2, .h2 {
            font-size:20px; line-height:30px; color:color(white); font-weight:700; font-family:$font-aptly; letter-spacing:0.01em;
          }
          p {
            margin-top:6px; margin-bottom:0;
            font-size:16px; line-height:22px; color:color(white);
          }
          .btn-round {
            padding:4px 12px;
            margin-top:16px;
            border:1px solid color(white);
            font-size:12px; color:color(white); text-transform:uppercase;
            background-color:rgba(255,255,255,.1);
            @include prefix(transition, (all .3s ease-out), webkit moz o ms);
          }

          @include media-breakpoint-up(lg) {
            width:50%;
            padding:64px 28px;

            h2, .h2 {
              font-size:42px; line-height:50px;
            }
            p {
              margin-top:18px;
              font-size:24px; line-height:28px;
            }
            .btn-round {
              padding:16px;
              margin-top:26px;
              border:2px solid color(white);
              font-size:18px;

              &:hover {
                background-color:rgba(255,255,255,0);
              }
            }
          }
        }
      }
    }
  }
}

.modal-backdrop {
  .show {
    opacity:0.7;
  }
}
