.weather-wrapper {
  margin-top:20px; margin-bottom:5px;

  > div{
    margin-top:20px;

    @include media-breakpoint-up(lg) {
      margin-top:0;
    }
    &:first-child{
      margin-top:0;
    }
  }
  .weather {
    position: relative;
    width:100%;
    margin-left:auto; margin-right:auto;
    text-align: center;

    &:before {
      content:'';
      position:absolute; left:0; top:0;
      width:100%; height:100%;
      opacity:0.25;
      z-index:1;
    }

    .weather-map {
      position:relative; float:none; display: inline-block;
      width:100%;
      padding: 20px 20px 0 20px;
      @include media-breakpoint-up(sm-428) {
        width:378px;
      }

      > * {
        position: relative;
        z-index: 2;
      }

      svg{
        margin: 20px auto 0 auto;
        width: 100%;
        @include media-breakpoint-up(sm-428) {
          width: 338px;
        }
      }

      .timing{
        position: absolute; top:22px; right:22px; left:inherit;
        width:auto;
        font-family: $font-vista; font-size:25px; line-height: 30px;
      }

      .ascona-map-location{
        position: absolute;
        font-size:15px; line-height: 15px; font-family: $font-vista; color:color(white); text-align: left;
        margin:0;
        width:min-content;

        @include media-breakpoint-down(sm-428) {
          &.location-locarno-ascona{
            left:40%!important; top:68%!important;
            i{
              left:-43%!important; top:-31px!important;
            }
          }
          &.location-brione-verzasca{
            left:69.2%!important; top:53%!important;
          }
        }

        i{
          position: absolute;
          font-size:35px; line-height:35px;
        }

        &.location-bosco-gurin{
          i{
            @include prefix(transform, translateX(-50%), webkit moz o ms);
          }
        }
        span{
          display: block;
          margin-top: 3px;
        }
      }
    }

    .weather-table {
      position:relative; z-index: 2;
      float:left;
      width:100%;
      padding:0 24px;

      .table-responsive{
        position: relative; z-index: 2;
      }
      &:before {
        content:'';
        position:absolute; left:0; top:0;
        width:100%; height:100%;
        opacity:0.1;
        z-index:1;
      }
      .table {
        //position:relative; z-index:2;
        text-align: left;

        >:not(:first-child){
          border-top:none;
        }

        thead{
          th{
            font-size:20px; line-height: 25px; color:color(grey); font-weight: normal; font-style: italic;
            border-bottom:1px solid color(grey-lighter); padding:20px 6px;
          }
        }
        tbody{
          tr{
            &:last-child{
              td{
                border-bottom:none; padding-bottom:5px;
              }
            }
          }
          td{
            font-size:20px; line-height: 25px; color:color(grey); font-weight: normal;
            border-bottom:1px solid color(grey-lighter); padding:20px 6px;
          }
        }
      }
    }
  }

  .weather-bottom {
    margin-top:18px;
    text-align:center; font-size:0;

    p {
      display:inline-block; float:none;
      width:auto;
      margin:0 10px;
      font-size:20px; line-height:25px; color:color(grey); font-family:$font_scotch; font-style:italic;
    }
  }
}

.daily-weather {
  margin-top:20px; margin-bottom:5px;

  ul {
    column-count: 1;
    @include media-breakpoint-up(sm) {
      column-count: 2;
    }
    @include media-breakpoint-up(lg) {
      column-count: 3;
    }
    @include media-breakpoint-up(xl) {
      column-count: 1;
    }
    li {
      display:flex; flex-direction:row;
      margin-top:20px;
      border-radius:10px;
      overflow:hidden;

      @include media-breakpoint-up(xl) {
        &:first-child {
          margin-top:0;
        }
      }

      > div {
        position:relative;
        float:left;

        &:before {
          content:'';
          position:absolute; left:0; top:0;
          width:100%; height:100%;
          z-index:1;
        }

        &.meteo {
          display:flex; flex-direction:column; align-items:center; justify-content:space-between;
          width:27.5%;
          padding:18px 14px 14px 14px;

          i {
            position:relative;
            width:35px; height:35px;
            font-size:35px; line-height:35px; color:color(white);
            z-index:2;
          }
          span {
            position:relative;
            width:100%;
            margin-top:8px;
            font-size:25px; line-height:30px; text-align:center; color:color(white); font-family:$font_scotch;
            z-index:2;
          }
        }
        &.info {
          width:72.5%;
          padding:20px 15px;

          &:before {
            opacity:0.1;
          }
          p {
            position:relative;
            margin-top:2px; margin-bottom:0;
            font-size:15px; line-height:20px; color:color(grey); font-family:$font_scotch;
            z-index:2;

            strong {
              font-size:20px; line-height:25px; font-weight:normal;
            }
          }
        }
      }
    }
  }
}
