$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts" !default;

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?ek2ndm');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?ek2ndm#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?ek2ndm') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?ek2ndm') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?ek2ndm##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-ascona-"], [class*=" icon-ascona-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

$icon-ascona-arrow-right-simple: "\e900";
$icon-ascona-close-simple: "\e901";
$icon-ascona-plus-simple: "\e902";
$icon-ascona-map: "\e903";
$icon-ascona-arrow-left: "\e904";
$icon-ascona-search: "\e905";
$icon-ascona-swisstainable: "\e906";
$icon-ascona-arrow-down-simple: "\e907";
$icon-ascona-cleanandsafe: "\e908";
$icon-ascona-meteo: "\e909";
$icon-ascona-info: "\e90a";
$icon-ascona-calendar: "\e90b";
$icon-ascona-downloads: "\e90c";
$icon-ascona-close: "\e90d";
$icon-ascona-sound-close: "\e90e";
$icon-ascona-family: "\e90f";
$icon-ascona-wellness: "\e910";
$icon-ascona-quality: "\e911";
$icon-ascona-outdoor: "\e912";
$icon-ascona-webcam: "\e913";
$icon-ascona-contact: "\e914";
$icon-ascona-transport: "\e915";
$icon-ascona-food: "\e916";
$icon-ascona-accomodation: "\e917";
$icon-ascona-tripadvisor: "\e918";
$icon-ascona-instagram: "\e919";
$icon-ascona-google: "\e91a";
$icon-ascona-facebook: "\e91b";
$icon-ascona-flickr: "\e91c";
$icon-ascona-pinterest: "\e91d";
$icon-ascona-share: "\e91e";
$icon-ascona-twitter: "\e91f";
$icon-ascona-youtube: "\e920";
$icon-ascona-sound: "\e921";
$icon-ascona-arrow-right: "\e922";
$icon-ascona-pause: "\e923";
$icon-ascona-play: "\e924";
$icon-ascona-menu: "\e925";
$icon-ascona-menu-close: "\e926";
$icon-ascona-plus: "\e927";
$icon-ascona-tendina: "\e928";
$icon-ascona-video: "\e929";
$icon-ascona-crown: "\e92a";
$icon-ascona-star: "\e92b";
$icon-ascona-swisshotels-logo: "\e92c";
$icon-ascona-arrow-down: "\e92d";
$icon-ascona-arrow-up: "\e92e";
$icon-ascona-business: "\e92f";
$icon-ascona-generic: "\e930";
$icon-ascona-offer: "\e931";
$icon-ascona-restaurant: "\e932";
$icon-ascona-arrows: "\e933";
$icon-ascona-clock: "\e934";
$icon-ascona-climbing: "\e935";
$icon-ascona-cycling: "\e936";
$icon-ascona-hiking: "\e937";
$icon-ascona-running: "\e938";
$icon-ascona-trail-running: "\e939";
$icon-ascona-loadmore: "\e93a";
$icon-ascona-tiktok: "\e93b";
$icon-ascona-accessibilita: "\e93c";
$icon-ascona-aria-condizionata: "\e93d";
$icon-ascona-meeting-diner_pxbxqsd: "\e93e";
$icon-ascona-meeting-reception: "\e93f";
$icon-ascona-meeting-school: "\e940";
$icon-ascona-meeting-theatre: "\e941";
$icon-ascona-microfono: "\e942";
$icon-ascona-altitude: "\e943";
$icon-ascona-proiettore: "\e944";
$icon-ascona-wifi: "\e945";
$icon-ascona-beds: "\e946";
$icon-ascona-activities: "\e947";
$icon-ascona-events: "\e948";
$icon-ascona-family1: "\e949";
$icon-ascona-gastronomy: "\e94a";
$icon-ascona-ispiration: "\e94b";
$icon-ascona-outdoor1: "\e94c";
$icon-ascona-places: "\e94d";
$icon-ascona-wellbeing: "\e94e";
$icon-ascona-twitter-x: "\e94f";
$icon-ascona-shop: "\e950";

.icon-ascona-arrow-right-simple {
  &:before {
    content: $icon-ascona-arrow-right-simple;
  }
}
.icon-ascona-close-simple {
  &:before {
    content: $icon-ascona-close-simple;
  }
}
.icon-ascona-plus-simple {
  &:before {
    content: $icon-ascona-plus-simple;
  }
}
.icon-ascona-map {
  &:before {
    content: $icon-ascona-map;
  }
}
.icon-ascona-arrow-left {
  &:before {
    content: $icon-ascona-arrow-left;
  }
}
.icon-ascona-search {
  &:before {
    content: $icon-ascona-search;
  }
}
.icon-ascona-swisstainable {
  &:before {
    content: $icon-ascona-swisstainable;
  }
}
.icon-ascona-arrow-down-simple {
  &:before {
    content: $icon-ascona-arrow-down-simple;
  }
}
.icon-ascona-cleanandsafe {
  &:before {
    content: $icon-ascona-cleanandsafe;
  }
}
.icon-ascona-meteo {
  &:before {
    content: $icon-ascona-meteo;
  }
}
.icon-ascona-info {
  &:before {
    content: $icon-ascona-info;
  }
}
.icon-ascona-calendar {
  &:before {
    content: $icon-ascona-calendar;
  }
}
.icon-ascona-downloads {
  &:before {
    content: $icon-ascona-downloads;
  }
}
.icon-ascona-close {
  &:before {
    content: $icon-ascona-close;
  }
}
.icon-ascona-sound-close {
  &:before {
    content: $icon-ascona-sound-close;
  }
}
.icon-ascona-family {
  &:before {
    content: $icon-ascona-family;
  }
}
.icon-ascona-wellness {
  &:before {
    content: $icon-ascona-wellness;
  }
}
.icon-ascona-quality {
  &:before {
    content: $icon-ascona-quality;
  }
}
.icon-ascona-outdoor {
  &:before {
    content: $icon-ascona-outdoor;
  }
}
.icon-ascona-webcam {
  &:before {
    content: $icon-ascona-webcam;
  }
}
.icon-ascona-contact {
  &:before {
    content: $icon-ascona-contact;
  }
}
.icon-ascona-transport {
  &:before {
    content: $icon-ascona-transport;
  }
}
.icon-ascona-food {
  &:before {
    content: $icon-ascona-food;
  }
}
.icon-ascona-accomodation {
  &:before {
    content: $icon-ascona-accomodation;
  }
}
.icon-ascona-tripadvisor {
  &:before {
    content: $icon-ascona-tripadvisor;
  }
}
.icon-ascona-instagram {
  &:before {
    content: $icon-ascona-instagram;
  }
}
.icon-ascona-google {
  &:before {
    content: $icon-ascona-google;
  }
}
.icon-ascona-facebook {
  &:before {
    content: $icon-ascona-facebook;
  }
}
.icon-ascona-flickr {
  &:before {
    content: $icon-ascona-flickr;
  }
}
.icon-ascona-pinterest {
  &:before {
    content: $icon-ascona-pinterest;
  }
}
.icon-ascona-share {
  &:before {
    content: $icon-ascona-share;
  }
}
.icon-ascona-twitter {
  &:before {
    content: $icon-ascona-twitter;
  }
}
.icon-ascona-youtube {
  &:before {
    content: $icon-ascona-youtube;
  }
}
.icon-ascona-sound {
  &:before {
    content: $icon-ascona-sound;
  }
}
.icon-ascona-arrow-right {
  &:before {
    content: $icon-ascona-arrow-right;
  }
}
.icon-ascona-pause {
  &:before {
    content: $icon-ascona-pause;
  }
}
.icon-ascona-play {
  &:before {
    content: $icon-ascona-play;
  }
}
.icon-ascona-menu {
  &:before {
    content: $icon-ascona-menu;
  }
}
.icon-ascona-menu-close {
  &:before {
    content: $icon-ascona-menu-close;
  }
}
.icon-ascona-plus {
  &:before {
    content: $icon-ascona-plus;
  }
}
.icon-ascona-tendina {
  &:before {
    content: $icon-ascona-tendina;
  }
}
.icon-ascona-video {
  &:before {
    content: $icon-ascona-video;
  }
}
.icon-ascona-crown {
  &:before {
    content: $icon-ascona-crown;
  }
}
.icon-ascona-star {
  &:before {
    content: $icon-ascona-star;
  }
}
.icon-ascona-swisshotels-logo {
  &:before {
    content: $icon-ascona-swisshotels-logo;
  }
}
.icon-ascona-arrow-down {
  &:before {
    content: $icon-ascona-arrow-down;
  }
}
.icon-ascona-arrow-up {
  &:before {
    content: $icon-ascona-arrow-up;
  }
}
.icon-ascona-business {
  &:before {
    content: $icon-ascona-business;
  }
}
.icon-ascona-generic {
  &:before {
    content: $icon-ascona-generic;
  }
}
.icon-ascona-offer {
  &:before {
    content: $icon-ascona-offer;
  }
}
.icon-ascona-restaurant {
  &:before {
    content: $icon-ascona-restaurant;
  }
}
.icon-ascona-arrows {
  &:before {
    content: $icon-ascona-arrows;
  }
}
.icon-ascona-clock {
  &:before {
    content: $icon-ascona-clock;
  }
}
.icon-ascona-climbing {
  &:before {
    content: $icon-ascona-climbing;
  }
}
.icon-ascona-cycling {
  &:before {
    content: $icon-ascona-cycling;
  }
}
.icon-ascona-hiking {
  &:before {
    content: $icon-ascona-hiking;
  }
}
.icon-ascona-running {
  &:before {
    content: $icon-ascona-running;
  }
}
.icon-ascona-trail-running {
  &:before {
    content: $icon-ascona-trail-running;
  }
}
.icon-ascona-loadmore {
  &:before {
    content: $icon-ascona-loadmore;
  }
}
.icon-ascona-tiktok {
  &:before {
    content: $icon-ascona-tiktok;
  }
}
.icon-ascona-accessibilita {
  &:before {
    content: $icon-ascona-accessibilita;
  }
}
.icon-ascona-aria-condizionata {
  &:before {
    content: $icon-ascona-aria-condizionata;
  }
}
.icon-ascona-meeting-diner_pxbxqsd {
  &:before {
    content: $icon-ascona-meeting-diner_pxbxqsd;
  }
}
.icon-ascona-meeting-reception {
  &:before {
    content: $icon-ascona-meeting-reception;
  }
}
.icon-ascona-meeting-school {
  &:before {
    content: $icon-ascona-meeting-school;
  }
}
.icon-ascona-meeting-theatre {
  &:before {
    content: $icon-ascona-meeting-theatre;
  }
}
.icon-ascona-microfono {
  &:before {
    content: $icon-ascona-microfono;
  }
}
.icon-ascona-altitude {
  &:before {
    content: $icon-ascona-altitude;
  }
}
.icon-ascona-proiettore {
  &:before {
    content: $icon-ascona-proiettore;
  }
}
.icon-ascona-wifi {
  &:before {
    content: $icon-ascona-wifi;
  }
}
.icon-ascona-beds {
  &:before {
    content: $icon-ascona-beds;
  }
}
.icon-ascona-activities {
  &:before {
    content: $icon-ascona-activities;
  }
}
.icon-ascona-events {
  &:before {
    content: $icon-ascona-events;
  }
}
.icon-ascona-family1 {
  &:before {
    content: $icon-ascona-family1;
  }
}
.icon-ascona-gastronomy {
  &:before {
    content: $icon-ascona-gastronomy;
  }
}
.icon-ascona-ispiration {
  &:before {
    content: $icon-ascona-ispiration;
  }
}
.icon-ascona-outdoor1 {
  &:before {
    content: $icon-ascona-outdoor1;
  }
}
.icon-ascona-places {
  &:before {
    content: $icon-ascona-places;
  }
}
.icon-ascona-wellbeing {
  &:before {
    content: $icon-ascona-wellbeing;
  }
}
.icon-ascona-twitter-x {
  &:before {
    content: $icon-ascona-twitter-x;
  }
}
.icon-ascona-shop {
  &:before {
    content: $icon-ascona-shop;
  }
}
