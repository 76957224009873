input, textarea, select {
  @include prefix(border-radius, 6px!important, webkit moz o ms);
  color:color(grey-light);
  &::placeholder{
    @include prefix(color, color(grey-light), webkit moz o ms);
  }
}
input{
  @include prefix(border-radius, 0!important, webkit moz o ms);
}

form {
  display:block; float:left;
  width:100%;

  > input, > select, > button, > textarea {
    display:block; float:left;
    padding:9px 8px; margin:0; border:1px solid color(grey-lighter);
    font-size:16px; line-height: 20px;
    background-color:color(grey-lightest);
    @include media-breakpoint-up(lg) {
      font-size:15px;
    }

    &[type="checkbox"]{
      padding:0; margin-right:6px;
      width:15px; height:15px;
      vertical-align: middle;
    }

    &[type="radio"]{
      padding:0; margin-right:6px;
      width:15px; height:15px;
      vertical-align: middle;
    }
  }
  > label{
    &:not(.checkbox-label){
      float:left;
      vertical-align: middle; line-height: 16px!important;
      margin-bottom: 7px; margin-right: 7px;
      cursor: pointer;
    }
  }
  > button, > input[type="submit"], > button[type="submit"]{
    display: inline-block;
    padding:9px 20px 9px 20px; margin:7px 7px 7px 0; border:1px solid color(grey-lighter);
    @include prefix(border-radius, 44px, webkit moz o ms);
    font-size:15px; line-height: 20px; color:color(grey-light); font-family: $font-vista;
    background-color:color(grey-lightest);
    @include media-breakpoint-up(lg) {
      &:hover{
        color:color(white);
      }
    }
  }
  > input{
    &[type="checkbox"], &[type="radio"]{
      clear:left;
      cursor: pointer;
    }
  }
  textarea {
    resize:none; font-size:16px;
    @include media-breakpoint-up(lg) {
      font-size:15px;
    }
  }
  fieldset {
    display:block; float:left;
    width:100%;
    input, textarea, select {
      @include prefix(appearance, none!important, webkit moz o ms);
    }

    input, select, button, textarea {
      display:block; float:left;
      padding:9px 8px; margin:0; border:1px solid color(grey-lighter);
      font-size:16px; line-height: 20px;
      background-color:color(grey-lightest);
      @include media-breakpoint-up(lg) {
        font-size:15px;
      }

      &[type="checkbox"]{
        padding:0;
        width:15px; height:15px;
      }
    }
    textarea {
      resize:none; font-size:16px;
      @include media-breakpoint-up(lg) {
        font-size:15px;
      }
    }
  }


  .checkmark:before{
    content:"";
    position:absolute; left:3px; top:6px; opacity:0;
    width: 9px; height: 9px;
    @include prefix(transition, all .3s ease-out, webkit moz o ms);
  }

  input[type="checkbox"]:checked+span:before{
    opacity:1;
  }

  input[type="checkbox"]:checked+span:after{
    content:""; position: absolute;
  }
  label{
    font-size:15px; line-height:20px; color:color(grey); font-family: $font-vista; font-weight: 700;

    &.checkbox-label, &.radio-label{
      position: relative; display: flex; float:left; cursor: pointer;
      font-size:15px; line-height:20px; color:color(grey); font-family: $font-vista; font-weight: 700;
      width:100%;

      input{
        visibility: hidden; position: absolute; top:0; left:0;
      }
      .checkmark {
        display: inline-block; float:left;
        width:15px; height:15px; min-width: 15px;
        line-height: 20px; vertical-align: middle;
        border: 1px solid color(grey-light); margin-top: 3px;
        &+span{
          float:left; display: inline-block;
          vertical-align: middle;
          margin-left: 7px;
        }
      }
      input:checked ~ .checkmark:after, input:checked ~ .checkmark:after, &:hover input ~ .checkmark:after, &:hover input ~ .checkmark:after{
        color:color(black);
      }
    }
  }
}

.select2-container{
  float:left; display: block;
  width:100%!important;
  margin-bottom:10px;

  @include media-breakpoint-up(lg) {
    margin-bottom:0;
  }
}
.select2-container--default .select2-selection--single{
  display:block; float:left;
  padding:9px 8px; margin:0; border:1px solid color(grey-lighter);
  font-size:15px; line-height: 20px;
  background-color:color(grey-lightest);
  width:100%; height:100%; max-height:40px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: inherit;
    font-size:15px; line-height: 20px; color:color(grey-light);
}
.select2-search--dropdown{
  background-color:color(grey-lightest);
}
.select2-container--default{
  &.select2-container--open{
    .select2-selection--single .select2-selection__arrow {
      b{
        border:0;

        &:before{
          transform: rotate(180deg);
        }
      }
    }
  }

  .select2-selection--single .select2-selection__arrow {
    position: absolute; top: 50%; right: 10px;
    height: 15px; width: 15px;

    b{
      position: absolute; left: 0; top: 0;
      width: 15px; height: 15px;
      margin-left: 0; margin-top: 0; border-width: 0;
      transform: translateY(-50%);

      &:before{
        content:$icon-ascona-tendina; display: block;
        font-family: "icomoon"; font-size:15px; line-height: 15px; color:color(grey-light); font-weight: normal;
      }
    }
  }
  .select2-results{
    > .select2-results__options{
      background-color:color(grey-lightest);
    }
  }
  .select2-results__option {
    font-size: 15px; line-height: 20px; color:color(grey-light);
  }
  .select2-results__option--selected{
    background-color:color(grey-lighter);
    color:color(grey);
  }
}


// alternative selects

/* SELECT WITH FILTERS */
.filters-select-wrapper{
  position: relative; float: left;
  margin-top:0; margin-bottom: 10px;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }

  a[data-toggle="collapse"]{
      position: absolute; display: block; top:0; left: 0; z-index:2;
      width:100%; height:100%;
  }
  .panel-title{
      position: relative;
  }
}
.daterangepicker{
  margin-top:15px;
}
.daterange-wrapper{
    overflow: hidden; position: relative; display:block; float:left;
    padding:9px 8px; margin:0; border:1px solid color(grey-lighter);
    font-size:16px; line-height: 20px; color:color(grey-light);
    background-color:color(grey-lightest);
    width:100%; height:100%; max-height:40px;
    @include prefix(border-radius, 4px, webkit moz o ms);

    &:hover, &:focus{
      text-decoration:none;
    }

    &:before{
      content:$icon-ascona-tendina; display: block; position: absolute; right: 0; top: 50%;
      font-family: "icomoon"; font-size:16px; line-height: 16px; color:color(grey-light);
      margin:0 10px 0 0;
      transform: translateY(-50%);
    }

    span{
      position: absolute; top:50%; left:8px; display: inline-block; z-index:2; pointer-events: none;
      white-space: normal; font-size:16px; letter-spacing: 0; color:color(grey-light);
      transform:translateY(-50%); -webkit-transform:translateY(-50%); -moz-transform:translateY(-50%); -o-transform:translateY(-50%);
    }
    input{
      &[data-type="daterange"]{
        padding-left:50px;
      }
    }
}
input{
    &[data-type="daterange"]{
        display: block; overflow: hidden; position: relative; outline: 0;
        width:100%;
        margin:0; padding:0; border:0; padding:0 35px 0 0;
        background-color:transparent;
        white-space: normal; font-size:16px; letter-spacing:0; color:color(grey-light);

        @include media-breakpoint-up(lg){
          white-space: nowrap;
          padding:0 30px 0 0;
        }

        &:hover, &:focus{
          text-decoration:none; color:color(grey-light); cursor: pointer;
        }
        &:before {
            content: ''; display: block; position: absolute; right: 0; top: 50%;
            font-size:18px; color:color(black); font-family:"icomoon"; content:$icon-ascona-arrow-right-simple; font-style: normal; font-weight:700;
            margin:0 14px 0 0; border:0;

            transform:translateY(-50%); -webkit-transform:translateY(-50%); -moz-transform:translateY(-50%); -o-transform:translateY(-50%);
        }
    }
}
a.filters-select{
  overflow: hidden; position: relative; display:block; float:left;
  padding:9px 8px; margin:0; border:1px solid color(grey-lighter);
  font-size:16px; line-height: 20px; color:color(grey-light);
  background-color:color(grey-lightest);
  width:100%; height:100%; max-height:40px;
  @include prefix(border-radius, 4px, webkit moz o ms);

  &:hover, &:focus{
    text-decoration:none; color:color(grey-light);
  }

  &:before{
    content:$icon-ascona-tendina; display: block; position: absolute; right: 0; top: 50%;
    font-family: "icomoon"; font-size:16px; line-height: 16px; color:color(grey-light);
    margin:0 10px 0 0;
    transform: translateY(-50%);
  }
}
ul{
  &.filter_select, &.list-filter {
    display:none; position: relative; left:50%; z-index: 3; top:100%;
    background-color:color(white);
    width:100%; max-width:90%;
    margin:10px auto 0; padding:7px 10px; border-radius:5px; border:1px solid #d1d1d1;
    list-style:none;
    transform:translateX(-50%); -webkit-transform:translateX(-50%); -moz-transform:translateX(-50%); -o-transform:translateX(-50%);
    color:color(grey);

    -webkit-transform: translate3d(-50%, 0, 0);
    -moz-transform: translate3d(-50%, 0, 0);
    -ms-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);

    &.active{
      display: block;
    }

    .filters-accordion{
      margin-top:6px;
      &:first-child{
        margin-top:0px;
      }
      > a {
        color:color(grey); font-size:18px;

        i {
          display: inline-block;
          margin-right:8px;
          color:color(grey); font-size:14px;
          @include prefix(transition, (all .3s ease-out), webkit moz o ms);
        }
      }
      > ul{
        display: none;
      }


      &.active{
        > a {
          i {
            @include prefix(transform, rotate(-45deg), webkit moz o ms);
          }
        }
        > ul{
          display: block;
        }
      }
    }

    li{
      display: block;
      width:100%;
      margin:0; padding:0;

      .checkbox-label{
        position: relative;
      }

        > span{
            cursor:pointer; display: inline-block; position: relative;
            width:100%;
            padding-left:25px;
            font-size:18px; line-height:26px; color:color(black);

            &:before{
                content:"-"; position: absolute; top:5px; left:0; display: block;
                width:16px; height:16px;
                font-size:16px; color:color(black); font-family:"icomoon"; line-height: 16px;
                transform-origin: center center;

                -webkit-transition:all 0.3s ease-out;
                        transition:all 0.3s ease-out;
            }

            &.active{
                &:before{
                    -ms-transform: rotate(45deg);
                    -webkit-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
        }

        h4 {
            margin:0;

            span{
                cursor:pointer; display: inline-block; position: relative;
                width:100%;
                padding-left:25px;
                font-size:18px; line-height:26px; color:color(black);

                &:before{
                    content:"+"; position: absolute; top:5px; left:0; display: block;
                    width:16px; height:16px;
                    font-size:16px; color:color(black); font-family:"icomoon"; line-height: 16px;
                    transform-origin: center center;

                    -webkit-transition:all 0.3s ease-out;
                            transition:all 0.3s ease-out;
                }

                &.active{
                    &:before{
                      -ms-transform: rotate(45deg);
                      -webkit-transform: rotate(45deg);
                      transform: rotate(45deg);
                    }
                }
            }
        }

        .filters-wrapper{
          position: absolute; visibility:none; opacity:0;

          -webkit-transition:opacity 0.3s ease-out, visibility 0.3s ease-out;
                  transition:opacity 0.3s ease-out, visibility 0.3s ease-out;

          &.active{
            position: relative;
            visibility: visible; opacity:1;
          }
        }


        .checkmark:before{
          content:"";
          position:absolute; left:3px; top:6px; opacity:0;
          width: 9px; height: 9px;
          @include prefix(transition, all .3s ease-out, webkit moz o ms);
        }

        input[type="checkbox"]:checked+span:before{
          opacity:1;
        }

        input[type="checkbox"]:checked+span:after{
          content:""; position: absolute;
        }
        label{
          font-size:15px; line-height:20px; color:color(grey); font-family: $font-vista; font-weight: 700;

          &.checkbox-label, &.radio-label{
            position: relative; display: flex; float:left; cursor: pointer;
            font-size:15px; line-height:20px; color:color(grey); font-family: $font-vista; font-weight: 700;
            width:100%;

            input{
              visibility: hidden; position: absolute; top:0; left:0;
            }
            .checkmark {
              display: inline-block; float:left;
              width:15px; height:15px; min-width: 15px;
              line-height: 20px; vertical-align: middle;
              border: 1px solid color(grey-light); margin-top: 3px;
              &+span{
                float:left; display: inline-block;
                vertical-align: middle;
                margin-left: 7px;
              }
            }
            input:checked ~ .checkmark:after, input:checked ~ .checkmark:after, &:hover input ~ .checkmark:after, &:hover input ~ .checkmark:after{
              color:color(black);
            }
          }
        }

      .panel-collapse {
        ul {
          padding:0;
        }
      }
    }

    &:before{
      content:""; position: absolute; top:-12px; left:50%;
      width: 0;  height: 0;
      border-left:5px solid transparent; border-right:5px solid transparent; border-bottom:12px solid color(grey-light);
      transform:translateX(-50%); -webkit-transform:translateX(-50%); -moz-transform:translateX(-50%); -o-transform:translateX(-50%);
    }
    &:after{
      content:""; position: absolute; top:-11px; left:50%;
      width: 0;  height: 0;
      border-left:4px solid transparent; border-right:4px solid transparent; border-bottom:11px solid color(white);
      transform:translateX(-50%); -webkit-transform:translateX(-50%); -moz-transform:translateX(-50%); -o-transform:translateX(-50%);
    }
  }
}
.filter-blocker{
  position: absolute; top:0; left:50%; display: block; z-index:-1; opacity:0;
  width:100vw; height:calc(100% + 15px);
  background-color:rgba(color(black), .25);
  transform:translateX(-50%); -webkit-transform:translateX(-50%); -moz-transform:translateX(-50%); -o-transform:translateX(-50%);


  -webkit-transition:visibility 0.3s ease-out, opacity 0.3s ease-out;
          transition:visibility 0.3s ease-out, opacity 0.3s ease-out;
}
.filter-open{
  .filter-blocker{
    z-index: 2; opacity:1;
  }
}

#mc_embed_signup{
  display: block; float: left;
  margin-top: 25px; margin-bottom: 25px;
  form {
    margin: 0!important; padding: 0!important;
    fieldset {
      p{
        margin-bottom:0;
      }
      label{
        font-family: $font_scotch; font-size: 16px; line-height: 21px; color: color(grey);
        margin-bottom:16px;
        @include media-breakpoint-up(sm-375){
          font-size: calc(16px + 4*(100vw - 375px)/1065); line-height: calc(21px + 4*(100vw - 375px)/1065);
        }
        @include media-breakpoint-up(xl-1440){
          font-size: 20px; line-height: 25px;
        }
        &.checkbox{
          cursor: pointer;
          position: relative; display: flex; float:left;
          width:100%;

          [type="checkbox"]:checked, [type="checkbox"]:not(:checked) {
            cursor: pointer;
            position: relative; display: inline-block; float: left;
            margin-top: 3px;
            min-width: 15px; width: 15px; height: 15px;
            line-height: 15px;
            vertical-align: middle;
            border: 1px solid #999;
          }

          [type="checkbox"]:checked:after {
              content:'✓';
              position:absolute; top:0; left:0;
              font-size: 17px; line-height: 17px;
              @include prefix(transition, all .3s ease-out, webkit moz o ms);
          }
        }
      }
    }
  }
  .clear{
    margin-bottom: 1rem;
  }
}

#hotel-contact-form{
  float:left;
  width:100%;
  .contactMe{
    width:100%;
    > header{
      position: relative;
    }
    .select2-container .select2-selection .select2-selection__rendered {
      height: 38px;
      padding-right: 30px; padding-left: 10px;
      font-size: 15px; line-height: 20px; color: #999;
    }
    .select2-container .select2-selection .select2-selection__arrow {
      position: absolute; top: 50%; right: 10px;
      height: 15px; width: 15px;
      b{
        position: absolute; left: 0; top: 0;
        width: 15px; height: 15px;
        margin-left: 0; margin-top: 0; border-width: 0;
        transform: translateY(-50%);
        &:before{
          content:$icon-ascona-tendina; display: block;
          font-family: "icomoon"; font-size:15px; line-height: 15px; color:color(grey-light); font-weight: normal;
        }
      }
    }
    input{
      margin-top:0;
    }
    input[type="submit"] {
      margin-top: 10px;
    }
  }
}
