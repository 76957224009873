.no-hero{
  .main-content{
    margin-top:130px;
    @include media-breakpoint-up(lg) {
      margin-top:185px;
    }
  }
  header.header {
    .header-menu {
      .search-btn,  .menu-btn {
        position: relative;
        background-color:transparent;
        &:before{
          content:""; position: absolute; top:0; left:0; display: block; z-index: 1;
          width:100%; height:100%;
          @include prefix(transition, (all .3s ease-out), webkit moz o ms);
        }
        i{
          position: relative; z-index: 2;
        }
      }
    }
  }
}

.no-hero.header-scroll{
  header.header{
    .header-menu{
      .search-btn,  .menu-btn{
        background-color:rgba(0,0,0,.20);
      }
    }
  }
}
header.header{
  position: fixed; top: 0; left: 0; z-index: 11;
  width: 100%;
  padding-top: 20px;
  @include prefix(transition, (all .3s ease-out), webkit moz o ms);

  .header-logo{
    position: relative; display: block; float:left; z-index: 2; left:0;
    width: 120px; height: 120px;
    @include prefix(transition, (all .3s ease-out), webkit moz o ms);

    @include media-breakpoint-up(lg) {
      width: 175px; height: 175px;
    }

    @include media-breakpoint-up(xl) {
      left:-($grid-column-gap/2);
    }

    img{
      position: absolute; bottom: 14px; left: 7px; display: block;
      width: calc(100% - 21px); height:auto;
      @include prefix(transition, (all .3s ease-out), webkit moz o ms);
    }
  }

  &:before{
    content:""; position:absolute; top:0; left:0; display: block; z-index: 1;
    width:100%; height:85px;
    @include prefix(transition, (all .3s ease-out), webkit moz o ms);
  }

  .header-menu{
    position: relative; float:right; z-index: 2; right:($grid-column-gap/2);
    font-size:0;

    @include media-breakpoint-up(xl) {
      right:0;
    }

    .search-btn{
      float:left; display: block;
      width:40px; height:40px;
      @include bglayer();
      background-color:rgba(0,0,0,.20);
      font-size:40px; line-height: 40px; color:color(white);
    }
    .menu-btn{
      float:left; display: block;
      width:40px; height:40px;
      font-size:40px; line-height: 40px; color:color(white);
      @include bglayer();
      margin-left: -2px;
    }
    .shop-btn {
      float:left; display: block;
      width:40px; height:40px;
      padding:0 6px;
      text-align:center; font-size:0;

      svg {
        display:inline-block; float:none;
        height:40px; width:auto;

        .filling {
          opacity:0;
          @include prefix(transition, (all .3s ease-out), webkit moz o ms);
        }
        .base {
          opacity:0.2;
        }
      }
      &.active {
        svg {
          .filling {
            opacity:1;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        &:hover {
          svg {
            .filling {
              opacity:1;
            }
          }
        }
      }
    }
  }
}

.search-layer, .menu-layer{
  display: block; position: fixed; left:0; z-index: 10; opacity: 0; top:-100%; overflow: hidden;
  width:100%; height:100%;
  padding-bottom:35px;
  background-color:color(white);
  @include prefix(transition, (opacity .3s ease-out), webkit moz o ms);

  &:after{
    position: absolute; top:0; left:0; display: block; content:""; z-index: 1; opacity:.1;
    width:100%; height: 100%;
  }
  >*{
    position: relative; z-index: 2;
  }
}
.editor-mode .menu-layer, .menu-layer.editor-mode {
  position: relative; opacity: 1;
}
.menu-lang{
  a{
    display: block; position: relative; float:left; clear:both;
    width:auto;
    padding-left:28px; margin-bottom:15px;
    color:color(grey); font-size:20px; line-height: 22px; font-family: $font-vista;
    @include prefix(transition, (all .3s ease-out), webkit moz o ms);

    &:before{
      content:""; display: block; position: absolute; top:0; left:0;
      border:1px solid color(grey); border-radius:50%;
      background-color:color(white);
      width:22px; height:22px;
    }
    &.active{
      &:after{
        content:""; display: block; position: absolute; top:4px; left:4px;
        border-radius:50%;
        background-color:color(grey);
        width:14px; height:14px;
      }
    }
  }
}
.menu-main{
  > ul{
    // safari grid fix
    @supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
    position: relative; padding-top:6px; margin-top:5px;

    &:before{
      content:""; top:0; left:-20px; position: absolute;
      width:100vw; height:1px;
      background-color:color(grey-lighter);
    }
    @include media-breakpoint-up(lg) {
      column-gap: 40px;
      margin-top: -40px;
      @include prefix(column-count, 3, webkit moz o ms);
      &:before{
        content:none;
      }
    }

    > li{
      @include media-breakpoint-up(lg) {
        margin-top: 40px;
        -webkit-column-break-inside:avoid-column; page-break-inside:avoid-column; break-inside:avoid-column;
      }

      h2, .h2, h3, .h3{
        position:relative;
        font-family:$font-vista; font-size:20px; line-height:25px;
        margin-bottom:6px; padding-left:29px; padding-bottom:6px;
        cursor:pointer;

        a{
          color:color(grey);
        }

        &:before{
          content:$icon-ascona-plus-simple; top:3px; left:2px; position: absolute;
          font-family: "icomoon"; font-size:18px; line-height: 18px;
          width:18px; height:18px;
          @include prefix(transition, (all .3s ease-out), webkit moz o ms);
        }
        &:after{
          content:""; bottom:0; left:-20px; position: absolute;
          font-family: "icomoon";
          width:100vw; height:1px;
          background-color:color(grey-lighter);
        }
        span{
          position: relative; display: block;
          &:before{
            content:""; bottom:-6px; left:-50px; position: absolute;
            width:100vw; height:calc(100% + 12px);
            background-color:#477ABA26;
            @include media-breakpoint-up(lg) {
              content:none;
            }
          }
        }
        @include media-breakpoint-up(lg) {
          padding-left:0; padding-bottom:0;
          &:before{
            content:none;
          }
          &:after{
            content:none;
          }
        }
      }
      .submenu{
        display: none;
        margin-top:-6px; margin-bottom:6px; margin-left:30px;

        @include media-breakpoint-up(lg) {
          display: block;
          margin-top:0; margin-bottom:0; margin-left:0;
        }
        > li{
          position: relative;
          padding-bottom:6px; padding-top:6px; margin-top:0; margin-bottom:0;
          max-width: calc(100% - 30px);

          @include media-breakpoint-up(lg) {
            max-width: 100%;
          }
          &:before{
            position: absolute; top:0; left:-50px; display: block; content:"";
            background-color:color(white);
            width:100vw; height:100%;
            @include media-breakpoint-up(lg) {
              content:none;
            }
          }
          &:not(:first-child){
            &:after{
              content:""; top:0; left:-50px; position: absolute;
              width:100vw; height:1px;
              background-color:color(grey-lighter);
              @include media-breakpoint-up(lg) {
                content:none;
              }
            }
          }
        }
      }
      &.open{
        .submenu{
          display: block;
        }
        h2, .h2, h3, .h3{
          &:before{
            @include prefix(transform, rotate(-45deg), webkit moz o ms);
          }
        }
      }
    }
    .arrow-list li{
      font-size:20px; line-height: 25px;
      margin-top: 6px; margin-bottom: 6px;

      a{
        font-size:20px; line-height: 25px;
        padding-left:29px;

        &:before{
          top:3px;
          font-size:18px; line-height: 18px;
        }
      }
    }
  }
}

.layer-content-wrapper{
  padding-top:23px;
  @include media-breakpoint-up(lg) {
    margin-left:17px;
  }
  p, h2, .h2, h3, .h3{
    color:color(grey); font-size:25px; line-height: 30px; font-family: $font-scotch; font-weight:400;
  }
  .search-wrapper{
    float:left;
    width:100%;
    margin-top:18px;

    input{
      width:100%; max-width:380px;
      margin-right:30px; margin-bottom: 18px;

      &::placeholder {
        color: color(grey-light);
        opacity: 1;
      }
    }
    .btn-round[type="submit"]{
      padding-left:30px; padding-right:30px;
    }
  }
}

body{
  &.menu-opened{
    overflow: hidden;
    .menu-layer{
      overflow: auto; overflow-x: hidden;
      &:after{
        position: fixed;
      }
    }
  }
  &.search-opened{
    overflow: hidden;
  }
}

.search-opened{
  .search-btn{
    .icon-ascona-search{
      &:before{
        content:$icon-ascona-menu-close;
      }
    }
  }
  .search-layer{
    padding-top:85px; opacity: 1; top:0;
  }
}
.menu-opened{
  .menu-btn{
    .icon-ascona-menu{
      &:before{
        content:$icon-ascona-menu-close;
      }
    }
  }
  .menu-layer{
    padding-top:85px; opacity: 1; top:0;
  }
}

.header-scroll{
  header.header{
    &#main-header{
      height:85px;

      .header-logo{
        width: 120px; height:38px;
        background-color:transparent;
        @include media-breakpoint-up(lg) {
          width: 175px;
        }

        img{
          bottom: -2px; left: 7px;
          width:calc(100% - 21px);
          @include media-breakpoint-up(lg) {
            bottom: -10px;
          }
        }
      }
    }
  }
}
